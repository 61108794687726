import React, { useEffect } from 'react';
import { usePage } from '@inertiajs/react';
import { toast } from '@/utils/Toast';
import { PostAuth } from '@/utils/PostAuth';
import AuthTracking from '@/utils/Auth/AuthTracking';
import ErrorWrapper from '@/views/ChefsDashboard/Components/Utils/ErrorWrapper';
import RequestWizard from '@/views/Wizards/RequestWizard/RequestWizard';
import OccasionsModal from '@/views/Occasions/OccasionsModal';
import DirectQuoteContainer from '@/views/DirectQuote/DirectQuoteContainer';
import Error from '@/Pages/Errors/Error';
import Header from './Partials/Header';
import Footer from './Partials/Footer';
import ToastsContainer from './Partials/ToastsContainer';

export default function Page({
    id = '',
    className = '',
    containerClass = '',
    contentOnly = false,
    sideBar = false,
    head = false,
    children,
    containerSize = false, // lg, xl, fluid
    reverseMobileLayout = false,
    headerProps = [],
    showFooter = true,
}) {
    const { auth, init, flash, ziggy } = usePage().props;
    const { settings } = init;
    const { route } = ziggy;

    // Handle post auth
    if (auth.check) PostAuth();

    // Handle flash messages
    useEffect(() => {
        if (flash?.success) toast({ type: 'success', text: flash.success });
        if (flash?.error) toast({ type: 'error', text: flash.error });
        if (flash?.auth) AuthTracking.track(flash.auth, { type: auth.type });
    }, [flash]);

    const renderContent = () => (
        <main
            id={id}
            className={`${
                containerSize ? 'container-' + containerSize : 'container'
            } page ${is_node ? '-ssr' : ''} ${className ?? ''}`}>
            {head && <div className="row">{head}</div>}

            <div
                className={`row body-container ${
                    reverseMobileLayout ? '-sm-reverse' : ''
                }`}>
                {!sideBar && <div className="col-12">{children}</div>}

                {sideBar && (
                    <>
                        <div className="container__left col-12 col-lg-8">
                            {children}
                        </div>
                        <div className="container__right col-12 col-lg-4">
                            {sideBar}
                        </div>
                    </>
                )}
            </div>
        </main>
    );

    const renderPage = () => {
        return (
            <div
                className={`page-container ${containerClass} ${
                    flash?.confetti ? '-confetti' : ''
                }`}>
                <Header {...headerProps} />

                {renderContent()}

                {/* Modals */}
                <DirectQuoteContainer _disableInertia={false} />
                <RequestWizard
                    autoShow={
                        [
                            'food-explorer',
                            'chefs',
                            'home',
                            'facet.demand-localization',
                        ].includes(route)
                            ? 5000
                            : route == 'landing.fine-dining'
                            ? 15000
                            : false
                    }
                />
                <OccasionsModal occasionTypes={settings.occasions.types} />

                <ToastsContainer />

                {showFooter && <Footer />}
            </div>
        );
    };

    const renderError = () => <Error status="front-end" />;

    // use this to debug errors on the page component (when you get a blank page)
    // return contentOnly ? renderContent() : renderPage();

    return (
        <ErrorWrapper view={renderError()} message="Inertia Front-end Error">
            {contentOnly ? renderContent() : renderPage()}
        </ErrorWrapper>
    );
}
