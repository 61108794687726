import React from 'react';
import { Carrot, Clock, Drop, UserPlus } from '@phosphor-icons/react';

export default function ServiceBlock(isQuote = false) {
  return (
    <div className={`direct-quote__service-block ${isQuote ? 'is_quote' : ''}`}>
      <span className='header'>Service includes</span>

      <div className='list-container'>
        <div className='item'>
          <div className='icon'>
            <Carrot size={22} />
          </div>

          <div className='copy'>
            <span className='description'>Ingredients</span>
          </div>
        </div>

        <div className='item'>
          <div className='icon'>
            <UserPlus size={22} />
          </div>

          <div className='copy'>
            <span className='description'>Chef serves</span>
          </div>
        </div>

        <div className='item'>
          <div className='icon'>
            <Clock size={22} />
          </div>

          <div className='copy'>
            <span className='description'>Chef's time</span>
          </div>
        </div>

        <div className='item'>
          <div className='icon'>
            <Drop size={22} />
          </div>

          <div className='copy'>
            <span className='description'>Cleanup</span>
          </div>
        </div>
      </div>
    </div>
  );
}
