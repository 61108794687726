import axios from 'axios';
// import Config from 'Config';

import {
    FETCH_BASKET,
    FETCH_BASKET_SUCCESS,
    FETCH_BASKET_ERROR,
    CLEAR_BASKET,
    UPDATE_BASKET_OPTION,
    UPDATE_POSTCODE,
    UPDATE_EXTRADISHESCOST,
    UPDATE_EVENT_DETAILS,
    UPDATE_BASKET_DISHES,
    UPDATE_SERVING_TYPE,
    UPDATE_TOTAL,
    GET_SUPPLY_DEMAND_DAY_RATE,
    GET_SUPPLY_DEMAND_DAY_RATE_SUCCESS,
    GET_SUPPLY_DEMAND_DAY_RATE_ERROR,
    UPDATE_TRAVEL_FEE,
} from '../constants/ActionTypes';

/* Fetch basket success action */
export const fetchBasketSuccess = (basket) => {
    return {
        type: FETCH_BASKET_SUCCESS,
        payload: basket,
    };
};

/* Fetch basket error action */
export const fetchBasketError = (error) => ({
    type: FETCH_BASKET_ERROR,
    payload: error,
});

/* Fetch dishes action */
export const fetchBasket = () => (dispatch, getState) => {
    const url = `/cart/session`;
    const request = axios.get(url);

    // Update reducer
    dispatch({
        type: FETCH_BASKET,
        payload: request,
    });

    return request
        .then((response) => {
            if (response.data.cart) {
                dispatch(fetchBasketSuccess(response));
            } else {
                dispatch(fetchBasketError());
            }
        })
        .catch((err) => {
            dispatch(fetchBasketError(err.response));
        });
};

export const updateBasketOption = (attribute, value) => {
    let payload = {};

    // If attribute is an object, then we're updating multiple attributes.
    if (typeof attribute === 'object') {
        payload = attribute;
    } else {
        // Otherwise, we're updating a single attribute.
        const convertValue =
            value === 'true' || value === 'false' ? value === 'true' : value;

        payload = { [attribute]: convertValue };
    }

    return {
        type: UPDATE_BASKET_OPTION,
        payload: payload,
    };
};

export const updateEventDetails = (data) => {
    return {
        type: UPDATE_EVENT_DETAILS,
        payload: data,
    };
};

export const updatePostcode = (basket) => {
    return {
        type: UPDATE_POSTCODE,
        payload: basket,
    };
};

export const updateExtraDishesCost = (basket) => {
    return {
        type: UPDATE_EXTRADISHESCOST,
        payload: basket,
    };
};

export const updateBasketDishes = (basket) => {
    return {
        type: UPDATE_BASKET_DISHES,
        payload: basket,
    };
};

export const toggleServingType = () => {
    return {
        type: UPDATE_SERVING_TYPE,
    };
};

/* Fetch basket error action */
export const updateTotalPrice = (
    price,
    pricePerGuest,
    guests,
    extrasCost,
    extraDishesTotal,
    dietaryCost,
    is_meal_prep,
    selectedMenuMultiplier,
    selectedSurgePrice,
    chef,
    totalDistance
) => {
    extrasCost = extrasCost ? extrasCost : 0;
    dietaryCost = dietaryCost ? dietaryCost : 0;
    extraDishesTotal = extraDishesTotal ? parseFloat(extraDishesTotal) : 0;
    chef = chef ? chef : false;
    totalDistance = totalDistance ? totalDistance : false;
    let travelFee = 0;

    let pct = 0;

    // Add the chef menu multiplier amount.
    if (selectedMenuMultiplier) {
        pct = parseFloat(selectedMenuMultiplier) / 100;
    }

    // Add the chef surge price amount.
    if (selectedSurgePrice) {
        pct = parseFloat(selectedSurgePrice) / 100;
    }

    price = parseInt(price * (1 + pct));

    let total = price + extrasCost + extraDishesTotal + dietaryCost;

    //  Add the chef travel fee.
    if (
        chef &&
        totalDistance > 0 &&
        totalDistance > chef.free_travel_distance
    ) {
        let nonFreeDistance = (totalDistance - chef.free_travel_distance) * 2; // x2 to get round trip distance.
        travelFee = nonFreeDistance * chef.travel_fee;
        travelFee = Math.round(
            travelFee + parseFloat((travelFee / 100) * Config.platform_fee_chef)
        );
        total += travelFee;
    }

    // total = parseInt(total * (1 + pct));

    let pricepp = total / guests;

    if (is_meal_prep) {
        extraDishesTotal = extraDishesTotal / guests;
        total = pricePerGuest + extrasCost + extraDishesTotal + dietaryCost;
        pricepp = total;
    }

    return {
        type: UPDATE_TOTAL,
        payload: total,
    };
};

export const updateTravelFee = (totalDistance, freeDistance, chefTravelFee) => {
    let travelFee = 0;

    if (totalDistance > freeDistance) {
        let nonFreeDistance = (totalDistance - freeDistance) * 2; // x2 to get round trip distance.
        travelFee = nonFreeDistance * chefTravelFee;
        travelFee = Math.round(
            travelFee * (1 + Config.platform_fee_chef / 100)
        );
    }

    return {
        type: UPDATE_TRAVEL_FEE,
        payload: travelFee,
    };
};

/* Update value */
export const getSupplyDemandSuccess = (response) => {
    return {
        type: GET_SUPPLY_DEMAND_DAY_RATE_SUCCESS,
        payload: response,
    };
};

export const getSupplyDemandError = (response) => {
    return {
        type: GET_SUPPLY_DEMAND_DAY_RATE_ERROR,
    };
};

/* Get supply and demaind values */
export const getSupplyDemand = (date, postcode) => (dispatch) => {
    let url = `/supply-demand/day`;

    const data = {
        date: date,
        postcode: postcode,
    };

    const request = axios.post(url, data);

    // Update reducer.
    dispatch({
        type: GET_SUPPLY_DEMAND_DAY_RATE,
        payload: request,
    });

    return request
        .then((response) => {
            dispatch(getSupplyDemandSuccess(response.data));
        })
        .catch((error) => {
            console.log(
                'There was an issue getting supply and demand date rate: ' +
                    error.response
            );
            dispatch(getSupplyDemandError(response));
        });
};
