export const selectStyling = {
    control: (styles, { isFocused }) => {
        return {
            ...styles,
            zIndex: 99,
            color: '#828894',
            fontSize: '16px',
            cursor: 'pointer',
            minHeight: '48px',
            padding: '0px 6px',
            borderRadius: '8px',
            backgroundColor: '#FFFFFF',
            boxShadow: isFocused ? '0px 0px 0px 4px #E5E5F1 !important' : '0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important',
            border: isFocused ? '1px solid #57579E !important' : '1px solid #D0D5DD !important',
        };
    },
    option: (styles, { isFocused, isSelected }) => {
        return {
            ...styles,
            zIndex: 99,
            color: '#828894',
            fontSize: '16px',
            padding: '8px 14px',
            cursor: 'pointer !important',
            backgroundColor: (isFocused) ? '#F2F2F8' : '#FFFFFF' ,
        };
    },
    menu: provided => ({ 
        ...provided, 
        zIndex: 98,
        marginTop: '0px',
        borderRadius: '8px',
    }),
    menuList: provided => ({ 
        ...provided, 
        overflow: 'auto',
        maxHeight: '125px',
        borderRadius: '8px',
    }),
    dropdownIndicator: base => ({
        ...base,
        color: '#667085'
    }),
    multiValue: (styles) => {
        return {
            ...styles,
            padding: '8px 12px',
            borderRadius: '100px',
            backgroundColor: '#F0EAE5 !important',
        };
    },
    multiValueLabel: (styles) => {
        return {
            ...styles,
            color: '#828894',
            fontSize: '14px',
            lineHeight: '20px',
            borderRadius: '100px',
            padding: '0px !important',
        };
    },
    multiValueRemove: (styles) => {
        return {
            ...styles,
            marginLeft: '8px',
            ':hover': {
                color: '#828894 !important',
            },
        };
    },
};