import { selectQuote, toStep } from '@/actions/DirectQuoteActions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectBlock from './SelectBlock';

export default function ExistingQuotesList() {
    const dispatch = useDispatch();
    const directQuote = useSelector((state) => state.directQuote);

    const handleSelectQuote = (quoteId) => {
        dispatch(toStep(99));
        dispatch(selectQuote(quoteId));
    };

    return (
        <>
            {directQuote?.existingQuotes?.map((quote, index) => {
                const iconClass =
                    directQuote?.options?.typeIconMapping[quote.type];
                const iconComponent =
                    directQuote?.options?.typeIconComponentMapping[quote.type];
                const icon =
                    iconComponent || iconClass || 'yh-new-icon-Cutlery'; // Use the fallback icon class if type does not exist in either mappings

                return (
                    <SelectBlock
                        icon={icon}
                        key={quote.id}
                        title={quote.type}
                        quoteId={quote.id}
                        postcode={quote.postcode}
                        selectQuote={handleSelectQuote}
                        mealDate={quote.meal_date_formatted}
                        checked={directQuote.selectedQuote == quote.id}
                    />
                );
            })}
        </>
    );
}
