import React from 'react';
import { useSelector } from 'react-redux';
import { useStep } from '@/hooks/wizard/useStep';
import NumberedProgress from '@/elements/nav/progress/NumberedProgress';
import ProgressBar from '@/elements/nav/progress/ProgressBar';

export default function RequestWizardProgress() {
    const { meta } = useSelector((state) => state.requestWizard);

    const { availableSteps, activeStep, toStep, isUnlocked } = useStep();

    const mappedSteps = availableSteps?.map((step, idx) => ({
        key: step.index,
        label: step.label,
        active: step.name === meta?.stepName,
        completed: step.isComplete,
        error: step.error,
        disabled: !isUnlocked(step.index),
        hidden: step.hideFromProgress,
    }));

    const jumpSteps = (step) => {
        if (!isUnlocked(step)) return;
        toStep(step);
    };

    if (activeStep?.hideNav) return;

    const renderSlim = () => {
        return (
            <div className="request-wizard__progress -sticky">
                <ProgressBar
                    percentage={
                        (activeStep?.index / availableSteps.length) * 100
                    }
                    speed={`0.5s`}
                    className="w-full !h-[5px] !rounded-none -bg-violet-200"
                    barClassName={`--bg-violet-700`}
                />
            </div>
        );
    };

    return renderSlim();

    return (
        <div className="row justify-center">
            <div className="col-12 col-md-5 pt-4">
                <div className="request-wizard__progress">
                    <NumberedProgress
                        steps={mappedSteps}
                        active={meta.step}
                        handleClick={(step) => jumpSteps(step)}
                    />
                </div>
            </div>
        </div>
    );
}
