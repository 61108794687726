import React from 'react';
import { Head } from '@inertiajs/react';

export default function SEO({ title, description, children }) {
    if (title && title.length > 60) {
        title = title.substring(0, 57) + '...';
    }

    let _title = title
            ? `${title} - yhangry`
            : 'yhangry - book a private chef for any occasion',
        _description =
            description ??
            'Book a private chef for a dining experience at home or in a rented venue. Browse chefs and menus with transparent pricing from £30pp.';

    // truncate description
    if (_description.length > 155) {
        _description = _description.substring(0, 152) + '...';
    }

    // escape double quotes
    _title = _title.replace(/"/g, '&quot;');
    _description = _description.replace(/"/g, '&quot;');

    return (
        <Head>
            <title>{_title}</title>
            <meta name="description" content={_description} />
            <meta property="og:title" content={_title} />
            <meta property="og:description" content={_description} />
            {children}
        </Head>
    );
}
