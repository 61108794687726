import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { router } from '@inertiajs/react';
import LoadingDots from '@/components/Loader/LoadingDots';

export default function RequestWizardSubmitting() {
    const { options, meta, status } = useSelector(
        (state) => state.requestWizard
    );

    useEffect(() => {
        if (!meta.quoteId || !status.submitted) return;
        
        window.location.href = route('customer-quote-invite-chefs', {
            quote: meta.quoteId,
        });
        // router.visit(
        //     route('customer-quote-invite-chefs', { quote: meta.quoteId }),
        //     {
        //         preserveState: true,
        //         preserveScroll: false,
        //     }
        // );
    }, [status.submitted]);

    return (
        <div className="request-wizard__submitting grow">
            {/* 3rd quarter of the page height */}
            <div />
            <div />
            <div className="text-center z-10">
                <LoadingDots />
                <h4 className="step-title text-center mt-3">
                    Your request is being sent to{' '}
                    {options.chefsCount > 10
                        ? options.chefsCount + ' chefs'
                        : '10 chefs'}
                    ...
                </h4>
                <p className="step-info text-center mb-0">
                    Next, you'll be able to see your matched chefs and you'll
                    <br />
                    be receiving personalised quotes which can be
                    <br />
                    customised to your preferences!
                </p>
            </div>
        </div>
    );
}
