import React from 'react';
import { CheckCircle, ShieldCheck } from '@phosphor-icons/react';

export default function BookingProtection({
    className = '',
    items = ['Fraud protection', 'Free 7 day cancellation', 'Insurance cover'],
}) {
    return (
        <div className={`booking-protection ${className}`}>
            <h6 className='mb-0 d-flex align-items-center'>
                <ShieldCheck size={20} weight="bold" className='mr-2' />
                Booking protection
            </h6>
            <div className="flex w-full md:w-auto justify-between items-center">
                {items.map((item, idx) => (
                    <div key={idx} className='flex items-center booking-protection-item'>
                        <CheckCircle size={18} weight='bold' className='mr-2' />
                        {item}
                    </div>
                ))}
            </div>
        </div>
    );
}
