import React from 'react';
import NavLink from '@/elements/NavLink';
import { usePage } from '@inertiajs/react';
import { removeBackdrop } from '@/utils/helpers';

export default function CustomerNavLinks() {
    const { auth } = usePage().props;

    return (
        <>
            <NavLink
                routeName="customers.dashboard"
                className="dropdown-item"
                onClick={removeBackdrop}>
                My events
            </NavLink>
            <NavLink
                routeName="my-messages"
                className="dropdown-item"
                onClick={removeBackdrop}>
                Messages
                {auth.unseenMessages > 0 && (
                    <span className="badge badge-primary badge-circle badge-sm ml-2">
                        {auth.unseenMessages}
                    </span>
                )}
            </NavLink>
            <NavLink
                routeName="favourites"
                params={{ type: 'chefs' }}
                className="dropdown-item"
                onClick={removeBackdrop}>
                Favourites
            </NavLink>
            <NavLink
                routeName="occasions.index"
                is_static={true}
                className="dropdown-item">
                Occasions
            </NavLink>
            <NavLink
                routeName="customer-wallet"
                is_static={true}
                className="dropdown-item">
                Wallet & referrals
            </NavLink>
            <div className="dropdown-divider"></div>
            <NavLink
                routeName="addresses"
                is_static={true}
                className="dropdown-item">
                Addresses
            </NavLink>
            <NavLink
                routeName="customer-account"
                is_static={true}
                className="dropdown-item">
                Account settings
            </NavLink>
            <div className="dropdown-divider"></div>
            <NavLink
                routeName="pricing-calculator"
                is_static={true}
                className="dropdown-item">
                Pricing
            </NavLink>
            <div className="dropdown-divider"></div>
            <NavLink
                routeName="logout"
                method="post"
                as="button"
                className="dropdown-item">
                Log out
            </NavLink>
        </>
    );
}
