import React from 'react';
import { usePage } from '@inertiajs/react';
import Logo from '@/elements/Logo';
import NavLink from '@/elements/NavLink';
import MobileNav from './MobileNav';
import _ from 'lodash';
import GuestNavLinks from './GuestNavLinks';
import CustomerNavLinks from './CustomerNavLinks';
import ChefNavLinks from './ChefNavLinks';

export default function Header(props) {
    const { auth, init } = usePage().props,
        { headerLinks } = init?.settings,
        { className = '', headerContent = null } = props;

    const renderHeaderLinks = () => {
        if (!headerLinks) return;

        return _.map(headerLinks, (url, text) => {
            let link = url.replace('^', ''),
                is_route = url.startsWith('^');
            return (
                <li className="menu-item" key={text}>
                    <NavLink
                        is_static={is_route == false}
                        href={is_route ? null : link}
                        routeName={is_route ? link : false}
                        title={text}>
                        {text}
                    </NavLink>
                </li>
            );
        });
    };

    const renderDropDown = () => {
        if (!auth) return;

        return (
            <div className="dropdown-menu">
                {auth.type == 'guest' && <GuestNavLinks />}
                {auth.type == 'customer' && <CustomerNavLinks />}
                {auth.type == 'chef' && <ChefNavLinks />}
            </div>
        );
    };

    const renderMobileHeader = () => (
        <>
            {/* Mobile Top Left */}
            <div className="d-flex flex-row d-lg-none header-mobile-left">
                <ul className="menu navbar-nav">
                    <li className="menu-item">
                        <span title="YHANGRY" className="brand">
                            <NavLink
                                href="/"
                                is_static={true}
                                title="YHANGRY"
                                rel="home"
                                className="navbar-brand">
                                <Logo height="24px" />
                            </NavLink>
                        </span>
                    </li>
                </ul>
            </div>

            {/* Mobile Center */}
            {headerContent && (
                <div className="d-flex header-center d-lg-none header-mobile-center">
                    {headerContent}
                </div>
            )}

            {/* Mobile Top Right */}
            <div className="d-flex d-lg-none header-mobile-right flex-1">
                <ul className="menu navbar-nav -right">
                    {auth.check && auth.activeQuote && (
                        <li>
                            <NavLink
                                routeName="customer-quote-responses"
                                className="btn btn-primary btn-xs"
                                params={{
                                    quote: auth?.activeQuote?.id,
                                }}>
                                View my quotes
                            </NavLink>
                        </li>
                    )}
                    {auth.check && !auth.activeQuote && (
                        <li className="menu-item">
                            <NavLink
                                routeName="my-messages"
                                is_static={auth.type == 'chef'}>
                                <button className="btn btn-gray btn-outline btn-icon">
                                    <i className="yh-new-icon-Comment---Text" />
                                    {auth.unseenMessages > 0 && (
                                        <span className="badge badge-primary badge-circle badge-xs badge-floating">
                                            {auth.unseenMessages}
                                        </span>
                                    )}
                                </button>
                            </NavLink>
                        </li>
                    )}
                    {!auth.check && (
                        <>
                            <li className="menu-item">
                                <NavLink routeName="chef-register-view">
                                    <button className="btn btn-dark btn-outline btn-xs">
                                        Become chef
                                    </button>
                                </NavLink>
                            </li>
                            <li className="menu-item">
                                <NavLink
                                    routeName="quote.landing"
                                    is_static={true}
                                    data-toggle="modal"
                                    data-target="#bespokeQuoteModal"
                                    data-source="header-nav"
                                    data-validate="true">
                                    <button className="btn btn-primary btn-xs">
                                        Find chef
                                    </button>
                                </NavLink>
                            </li>
                        </>
                    )}
                </ul>
            </div>
        </>
    );

    const renderDesktopHeader = () => (
        <>
            {/* Desktop Left */}
            <div
                id="main-menu"
                className=" navbar-collapse header-menu flex-row collapse sm:visible">
                <ul className="menu navbar-nav">
                    <li className="menu-item -logo">
                        <span title="YHANGRY" className="brand">
                            <NavLink
                                href="/"
                                is_static={true}
                                title="YHANGRY"
                                rel="home"
                                className="navbar-brand">
                                <Logo height="40px" />
                            </NavLink>
                        </span>
                    </li>
                    {renderHeaderLinks()}
                    {auth.check && auth.activeQuote && (
                        <li className="menu-item">
                            <NavLink
                                routeName="customer-quote-responses"
                                params={{
                                    quote: auth?.activeQuote?.id,
                                }}>
                                View my quotes
                            </NavLink>
                        </li>
                    )}
                </ul>
            </div>

            {/* Desktop Center */}
            {headerContent && (
                <div className="header-menu header-center collapse sm:visible">
                    {headerContent}
                </div>
            )}

            {/* Desktop Right */}
            <div className=" navbar-collapse header-menu collapse sm:visible">
                <ul className="menu navbar-nav -right">
                    {auth.check && (
                        <li className="menu-item -messages">
                            <NavLink
                                routeName="my-messages"
                                is_static={auth.type == 'chef'}>
                                <button className="btn btn-gray btn-outline btn-lg btn-icon">
                                    <i className="yh-new-icon-Comment---Text" />
                                    {auth.unseenMessages > 0 && (
                                        <span className="badge badge-primary badge-circle badge-sm badge-floating">
                                            {auth.unseenMessages}
                                        </span>
                                    )}
                                </button>
                            </NavLink>
                        </li>
                    )}
                    <li className="menu-item">
                        <div className="dropdown">
                            <button
                                className="btn btn-gray btn-outline btn-lg btn-rounded d-flex align-items-center"
                                type="button"
                                data-toggle="dropdown"
                                aria-expanded="false">
                                <div className="avatar rounded-circle">
                                    {auth.check && auth.user?.first_name && (
                                        <span className="fw-medium text-uppercase">
                                            {auth.user.first_name.charAt(0)}
                                            {auth.user.last_name?.charAt(0)}
                                        </span>
                                    )}
                                    {(!auth.check ||
                                        !auth.user?.first_name) && (
                                        <i className="yh-new-icon-User fs-20" />
                                    )}
                                </div>
                                <i className="yh-new-icon-Chevron---Down fw-bold fs-xl" />
                            </button>
                            {renderDropDown()}
                        </div>
                    </li>
                    {!auth.check && (
                        <li className="menu-item">
                            <NavLink routeName="chef-register-view">
                                <button className="btn btn-dark btn-outline btn-lg">
                                    Become a chef
                                </button>
                            </NavLink>
                        </li>
                    )}
                    <li className={`menu-item ${auth.check ? '' : 'm-0'}`}>
                        <NavLink
                            routeName="quote.landing"
                            is_static={true}
                            data-toggle="modal"
                            data-target="#bespokeQuoteModal"
                            data-source="header-nav"
                            data-validate="true">
                            <button className="btn btn-primary btn-lg">
                                Find local chef
                            </button>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    );

    return (
        <>
            <header id="header" className={`header--app ${className}`}>
                <div className={`container-xl position-relative`}>
                    <div className="navbar-container">
                        <nav className="navbar w-100">
                            {renderMobileHeader()}
                            {renderDesktopHeader()}
                        </nav>
                    </div>
                </div>
            </header>
            <MobileNav />
        </>
    );
}
