import React from 'react';
import NavLink from '@/elements/NavLink';

export default function GuestNavLinks() {
    return (
        <>
            <span className="font-bold px-3 pt-2 pb-0 opacity-40 inline-block">
                Customers
            </span>
            <NavLink
                routeName="customer-login"
                is_static={true}
                className="dropdown-item"
                data-toggle="modal"
                data-target="#customerLoginModal">
                Log in
            </NavLink>

            <NavLink
                routeName="customer-register"
                is_static={true}
                className="dropdown-item"
                data-toggle="modal"
                data-target="#customerLoginModal">
                Sign up
            </NavLink>
            <div className="dropdown-divider"></div>
            <span className="font-bold px-3 pt-2 pb-0 opacity-40 inline-block">
                Chefs
            </span>
            <NavLink
                routeName="chef-login"
                is_static={true}
                className="dropdown-item"
                data-toggle="modal"
                data-target="#customerLoginModal">
                Chef log in
            </NavLink>
            <NavLink
                className="dropdown-item"
                routeName="chef-register-view">
                Apply as a chef
            </NavLink>
        </>
    );
}
