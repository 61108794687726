import React, { useState, useEffect } from 'react';

import CoreStep from './CoreStep';
import InfoBlock from '../Partials/InfoBlock';
import ServiceBlock from '../Partials/ServiceBlock';
import { useDispatch, useSelector } from 'react-redux';
import { updateAttribute, setSelectedDishes } from '@/actions/DirectQuoteActions';
import Input from '@/elements/Input';

export default function Budget({progressBlock, title, progressTitle, stepsToSkipNext = false, stepsToSkipPrev = false, isLastStep = false, stepName = null}) {
    const dispatch = useDispatch();
    const [editBudget, setEditBudget] = useState(true);
    const basketData = useSelector((state) => state.basketData);
    const directQuote = useSelector((state) => state.directQuote);
    // const [editBudget, setEditBudget] = useState(() => directQuote.quote.budget && directQuote.quote.budget > 0 ? false : true);

    useEffect(() => {
        if (directQuote.quote.source == 'chef-menu') {
            dispatch(setSelectedDishes(directQuote));
        }
    }, []);

    const handleBudget = () => {
        setEditBudget(true);
    };

    const isValid = () => {
        if (!directQuote.quote.budget || directQuote.quote.budget < 100) {
            return false;
        } else {
            return true;
        }
    }
    
    const renderStep = () => {
        let medianPP = (basketData?.base_setmenu_details?.chef?.analytics?.median_converted_amount_pp > 0) ? 
            basketData?.base_setmenu_details?.chef?.analytics?.median_converted_amount_pp : basketData?.base_setmenu_details?.chef?.min_pp;
        
        return (
            <>
                {directQuote.quote.source == 'chef-menu' && basketData?.base_setmenu_details?.format != 1 ? (
                    <InfoBlock 
                        icon={'yh-new-icon-Alert---Circle'}
                        description={`Based on your menu selection, the price should be around £${parseInt(directQuote.quote.budgetPp * directQuote.quote.guests)}. Set a price that gets the chefs attention. </br><b>Prices are always negotiable after.</b>`}
                    />
                ) : (
                    <InfoBlock 
                        icon={'yh-new-icon-Alert---Circle'}
                        description={`This chef’s minimum spend is £${basketData?.base_setmenu_details?.chef?.min_spend} and average menu price is £${Math.round(medianPP)} per person. Please set an amount you would be happy to pay for this menu.`}
                    />
                )}

                {directQuote.quote.guests >= 10 &&
                    <InfoBlock 
                        icon={'yh-new-icon-Alert---Circle'}
                        description={`Your group size is ${directQuote.quote.guests} people. We suggest adding a server to improve your experience (approx. £100-£150 extra per server).`}
                        
                    />
                }

                <div className={`${editBudget ? '' : 'form-group -edit'}`}>
                    <div className='form-item'>
                        {editBudget ? (
                            <Input
                                label="Your budget"
                                min={100}
                                id='budget'
                                inputType='money'
                                decimals={0}
                                placeholder='0.00'
                                value={directQuote.quote.budget ?? 0}
                                inputClass='-unstyled'
                                handleChange={(value) => dispatch(updateAttribute('budget', parseFloat(value)))}
                            />
                        ) : (
                            <>
                            <label>Your budget</label>
                            <span className='details'>£{(Math.round(directQuote.quote.budget * 100) / 100).toFixed(2)}</span>
                            </>
                        )}
                    </div>

                    {!editBudget && <div className='form-edit' onClick={handleBudget}>Edit</div>}
                </div>

                <ServiceBlock />
            </>
        );
    };

    return <CoreStep 
        title={title}
        isValid={isValid}
        stepName={stepName}
        renderStep={renderStep} 
        isLastStep={isLastStep}
        progressBlock={progressBlock}
        progressTitle={progressTitle}
        stepsToSkipNext={stepsToSkipNext}
        stepsToSkipPrev={stepsToSkipPrev}
    />;
}
