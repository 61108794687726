import React, { useState, useMemo } from 'react';

import CoreStep from './CoreStep';
import Auth from '../../Auth/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { nextStep, onLogin, saveQuoteToSession } from '../../../actions/DirectQuoteActions';

export default function Authentication({progressBlock, title, progressTitle, stepsToSkipNext = false, stepsToSkipPrev = false, isLastStep = false, stepName = null}) {
    const dispatch = useDispatch();

    const isValid = () => {
        return false
    }

    const handleLogin = () => {
        dispatch(onLogin());
        dispatch(nextStep(false));
    };

    const renderStep = () => {
        return (
            <>
                <Auth
                    embed={true}
                    removeTitle={true}
                    beforeSocialAuth={() => dispatch(saveQuoteToSession())}
                    callback={() => handleLogin()}
                />
            </>
        );
    };

    return <CoreStep 
        title={title}
        isValid={isValid}
        stepName={stepName}
        disableNextCta={true}
        renderStep={renderStep} 
        isLastStep={isLastStep}
        progressBlock={progressBlock}
        progressTitle={progressTitle}
        stepsToSkipNext={stepsToSkipNext}
        stepsToSkipPrev={stepsToSkipPrev}
    />;
}
