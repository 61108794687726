import React, { Component, Suspense, lazy } from 'react';

import { Provider } from 'react-redux';
import configureStore from '../../configureStore';
// const DirectQuote = lazy(() => import('./DirectQuote'));
import DirectQuote from './DirectQuote';

const store = configureStore();

class DirectQuoteContainer extends Component {
    render() {
        return (
            <Provider store={store}>
                <Suspense fallback={<></>}>
                    <DirectQuote
                        source={this.props._source}
                        setMenuId={this.props._setMenuId}
                        disableInertia={this.props._disableInertia}
                    />
                </Suspense>
            </Provider>
        );
    }
}

export default DirectQuoteContainer;
