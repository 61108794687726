import React from 'react';
import { usePage } from '@inertiajs/react';
import NavLink from '@/elements/NavLink';
import _ from 'lodash';

export default function Footer() {
    const { footerLinks } = usePage().props?.init?.settings;

    const addIcons = (text, image) => {
        const iconClass = text.split('[').pop().split(']')[0],
            textArray = text.split('[' + iconClass + ']');

        return textArray.map((str, idx) => (
            str ? (
                str
            ) : (
                image ? (
                    <>
                        <img
                            key={`img-` + idx}
                            border="0"
                            width="22"
                            height="22"
                            src={image}
                            alt="Whatsapp logo"
                            className="v-align-middle" 
                        />
                    </>
                    
                ) : (
                    <i className={`v-align-middle ${iconClass}`} key={`icon-` + idx} />
                )
            )
        ));
    };

    const renderFooterLinks = () => {
        if (!footerLinks) return;

        let lines = footerLinks.split('\r\n'),
            groups = [],
            group = [],
            groupIdx = 0;

        _.map(lines, (line) => {
            if (line === '--') {
                groups[groupIdx] = group;
                group = [];
                groupIdx++;
            } else {
                group.push(line);
            }
        });

        // add the last column
        groups[groupIdx] = group;

        if (!groups.length) return;

        return _.map(groups, (group, idx) => (
            <div className="col-md col-sm-12" key={`col-` + idx}>
                <ul className="menu footer-nav">
                    {_.map(group, (item, itemIdx) => {
                        let values = item.split(': '),
                            name = values[0].replace('*', ''),
                            namePrint = name.replace(/\[.*?\]/, ''),
                            nameHTML = addIcons(name, values[2]),
                            link = values[1].replace('^', ''),
                            is_title = values[0].startsWith('*'),
                            is_route = values[1].startsWith('^'),
                            is_modal = values[1].startsWith('#');
                        return (
                            <li
                                key={`item-` + itemIdx}
                                className={`nav-item menu-item ${
                                    is_title ? 'nav-title mb-2' : ''
                                }`}>
                                <NavLink
                                    href={is_route ? null : link}
                                    routeName={is_route ? link : false}
                                    is_static={is_route === false}
                                    data-toggle={is_modal ? 'modal' : null}
                                    data-target={is_modal ? link : null}
                                    target="_self"
                                    className="nav-link"
                                    title={namePrint}>
                                    {nameHTML}
                                </NavLink>
                            </li>
                        );
                    })}
                </ul>
            </div>
        ));
    };

    return (
        <>
            <footer id="footer" className="bg-white">
                <div className={`container-xl footer-desktop pb-0`}>
                    <div className="row">
                        <div className="col-md-5 col-sm-12">
                            <div className="row">
                                <div className="col-md-10 col-sm-12">
                                    <div className="footer-title-container">
                                        <h2 className="footer-title">
                                            Find the best private chef for your
                                            event
                                        </h2>
                                        <NavLink
                                            routeName="quote.landing"
                                            is_static={true}
                                            data-toggle="modal"
                                            data-target="#bespokeQuoteModal"
                                            data-source="footer-nav"
                                            className="btn btn-primary btn-xl mt-4">
                                            Find local chef
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {renderFooterLinks()}
                    </div>
                    <div className="footer-line2 d-flex justify-content-between align-items-center mt-5">
                        <div className="footer-line2-left d-flex align-items-center">
                            &copy; {new Date().getFullYear()} YHANGRY - All
                            rights reserved
                            <div className="d-flex">
                                <span className="inline-separator wide">
                                    {' '}
                                    •{' '}
                                </span>
                                <NavLink
                                    href="https://yhangry.com/terms-and-conditions/"
                                    is_static={true}
                                    target="_blank"
                                    className="p-0">
                                    Terms
                                </NavLink>
                                <span className="inline-separator wide">
                                    {' '}
                                    •{' '}
                                </span>
                                <NavLink
                                    href="https://yhangry.com/privacy-policy/"
                                    is_static={true}
                                    target="_blank"
                                    className="p-0">
                                    Privacy
                                </NavLink>
                                <span className="inline-separator wide">
                                    {' '}
                                    •{' '}
                                </span>
                                <NavLink
                                    routeName="sitemap-page"
                                    is_static={true}
                                    target="_blank"
                                    className="p-0">
                                    Sitemap
                                </NavLink>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <p className="m-0 mr-3">Follow us on</p>
                            <div className="social d-flex align-items-center">
                                <a
                                    href="https://www.facebook.com/yhangry/"
                                    title="Find us on Facebook"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    width="24" 
                                    height="24" 
                                    viewBox="0 0 48 48">
                                        <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"></path>
                                    </svg>
                                </a>
                                <a
                                    href="https://www.instagram.com/yhangry/"
                                    title="Find us on Instagram"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="#FFFFFF"
                                        viewBox="0 0 24 24">
                                        <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                    </svg>
                                </a>
                                <a
                                    href="https://twitter.com/YhangryChef"
                                    title="Find us on Twitter"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="#FFFFFF"
                                        viewBox="0 0 24 24">
                                        <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                                    </svg>
                                </a>
                                <a
                                    href="https://www.youtube.com/channel/UC7_r0WdFoUsAiYu1cIaaWHg"
                                    title="Find us on YouTube"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        x="0px" 
                                        y="0px" 
                                        width="26" 
                                        height="26" 
                                        viewBox="0 0 48 48">
                                        <path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"></path>
                                    </svg>
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/yhangry/"
                                    title="Find us on Linkedin"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="24" 
                                        height="24" 
                                        viewBox="0 0 48 48">
                                        <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
                                    </svg>
                                    </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
