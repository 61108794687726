import React from 'react';

export default function ProgressBar({
    className = '',
    barClassName = '',
    percentage = 0,
    transitionDelay = '0s',
    speed = '0.2s',
}) {
    return (
        <div className={`progress ${className}`}>
            <div
                className={`progress-bar ${barClassName} ${
                    percentage >= 100 ? '-completed' : ''
                }`}
                role="progressbar"
                style={{
                    width: `${percentage}%`,
                    transition: `width ${speed} linear ${transitionDelay}`,
                }}
                aria-valuenow={percentage}
                aria-valuemin="0"
                aria-valuemax="100"
            />
        </div>
    );
}
