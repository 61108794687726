import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMeta } from '@/actions/wizard/RequestWizardActions';
import useWizard from './useWizard';
import { useStep } from './useStep';

const useListeners = () => {
    const dispatch = useDispatch();
    const { meta, status } = useSelector((state) => state.requestWizard);
    const { next, activeStep } = useStep();
    const { exit } = useWizard();

    /**
     * MARK: Listeners
     */
    useEffect(() => {
        const modalElement = $('#' + meta.modalId);
        if (!modalElement) return;

        modalElement.on('shown.bs.modal', onShow);
        modalElement.on('hidden.bs.modal', onHide);
        modalElement.on('keydown.dismiss.bs.modal', onEscape);

        // Clean up the event listeners when the component unmounts
        return () => {
            modalElement.off('shown.bs.modal', onShow);
            modalElement.off('hidden.bs.modal', onHide);
            modalElement.off('keydown.dismiss.bs.modal', onEscape);
        };
    }, [meta.modalId]);

    // useEffect(() => {
    //     const modalElement = $('#' + meta.modalId);
    //     if (!modalElement) return;

    //     modalElement.on('keypress', onEnterPress);

    //     // Clean up the event listeners when the component unmounts
    //     return () => {
    //         modalElement.off('keypress', onEnterPress);
    //     };
    // }, [activeStep]);

    /**
     * MARK: Handlers
     */
    const onShow = (e = false) => {
        // Prevent re-tracking when social auth is happening
        if (status.pending && window?.userId) return;

        const data = {
            startedAt: new Date(),
        };

        // When triggered by button, update source and ctaCopy
        if (e) {
            const button = $(e.relatedTarget);
            const source = button.data('source');
            const copy = button.data('copy') ?? button.text();
            if (source) data.source = source;
            if (copy) data.ctaCopy = copy;
        }

        dispatch(updateMeta(data));
    };

    const onHide = () => {
        const date = new Date();

        dispatch(updateMeta({ exitedAt: date }));
    };

    const onEscape = (e) => {
        if (e.which !== 27) return; // esc key
        exit('keyboard', 'esc');
    };

    const onEnterPress = (e) => {
        if (e.which !== 13) return; // enter key
        if (!activeStep?.skippable && !activeStep?.isComplete) return;
        next();
    };
};

export default useListeners;
