import React from 'react';
import NavLink from '@/elements/NavLink';

export default function ErrorMessage({
    status = 'unexpected',
    cta = (
        <NavLink routeName="home" title="Home Page" className="btn btn-primary">
            Home Page
        </NavLink>
    ),
}) {
    const title = {
        503: 'Oops, something went wrong (503)',
        500: 'Oops, something went wrong (500)',
        404: 'Page not found (404)',
        403: 'Forbidden (403)',
        419: 'The page expired (419)',
        'front-end': 'Something went wrong!',
        unexpected: 'Unexpected error',
        'wizard-error': 'Oops, something went wrong',
    }[status];

    const description = {
        503: "If you are having trouble, please contact customer support or get in touch on live chat and we'll be happy to help",
        500: "If you are having trouble, please contact customer support or get in touch on live chat and we'll be happy to help.",
        404: 'The requested URL does not exist. Please try another page or click the below button to return to home.',
        403: 'Sorry, you are forbidden from accessing this page.',
        419: "If you are having trouble, please contact customer support or get in touch on live chat and we'll be happy to help.",
        'front-end':
            'Please refresh the page and try again, if this problem persists, please get in touch with us.',
        unexpected:
            'Please refresh the page and try again, if this problem persists, please get in touch with us.',
        'wizard-error':
            'Please refresh the page and try again, if this problem persists, please get in touch with us.',
    }[status];

    return (
        <div className={`error-${status} text-center my-5 py-5`}>
            <h1 className="fw-light font-lower">{title}</h1>
            <p className="mt-3 mb-4">{description}</p>
            <div className="btn-wrapper align--center">{cta && cta}</div>
        </div>
    );
}
