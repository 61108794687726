import React, { useState } from 'react';

export default function LoadingDots({ className = '', speed = 350, dots = 8 }) {
    const [active, setActive] = useState(0);

    setTimeout(() => {
        setActive((active + 1) % dots);
    }, speed);

    return (
        <div className={`loader__loading-dots ${className}`}>
            {[...Array(dots)].map((_, i) => (
                <div
                    key={i}
                    className={`loader__loading-dots-dot w-2 h-2 rounded-full m-1 d-inline-block ${
                        active === i ? 'bg-black' : '-bg-grey-200'
                    }`}
                />
            ))}
        </div>
    );
}
