
import React, { useState, useEffect, useCallback } from 'react';

import axios from 'axios';
import CoreStep from './CoreStep';
import { debounce } from 'lodash';
import Autocomplete from 'react-autocomplete'
import InfoBlock from '../Partials/InfoBlock';
import { useDispatch, useSelector } from 'react-redux';
import { updateAttribute } from '../../../actions/DirectQuoteActions';

export default function Location({ progressBlock, title, progressTitle, stepsToSkipNext = false, stepsToSkipPrev = false, isLastStep = false, stepName = null}) {
    const dispatch = useDispatch();
    const [postcodes, setPostcodes] = useState([]);
    const [editPostcode, setEditPostcode] = useState(true);
    const [isPostcodeValid, setIsPostcodeValid] = useState(true);
    const [isInternational, setIsInternational] = useState(false);
    const directQuote = useSelector((state) => state.directQuote);
    // const [editPostcode, setEditPostcode] = useState(() => directQuote.quote.postcode ? false : true);

    const handleEditPostcode = () => {
        setEditPostcode(true);
    };

    const postcodeValidation = useCallback(
        debounce((postcode) => {
            if (!postcode) return;

            const url = `/postcode/autocomplete/${postcode}`;

            const request = axios
                .get(url)
                .then((response) => {
                    setPostcodes(response.data?.postcodes);
                    setIsPostcodeValid(response.data?.isValid);
                    setIsInternational(response.data?.isInternational);
                })
                .catch((error) => {
                    setPostcodes([]);
                    setIsPostcodeValid(false);
                    setIsInternational(false);
                    setError('There was an error while validating address: ' + error);
                });
        }, 500),
        [directQuote]
    );


    useEffect(() => {
        postcodeValidation(directQuote.quote.postcode);
    }, [directQuote.quote.postcode]);

    const isValid = () => {
        if (!directQuote.quote.postcode || !directQuote.quote.addressType || !isPostcodeValid || isInternational) {
            return false;
        } else {
            return true;
        }
    };

    const renderStep = () => {
        return (
            <>
                <div className={`form-group ${editPostcode ? '' : '-edit'}`}>
                    <div className='form-item'>
                        <label htmlFor='location'>Event address</label>
                        {editPostcode ? (
                            <>
                                <div className='autocomplete input-group'>
                                    <Autocomplete
                                        id='postcode'
                                        items={postcodes}
                                        getItemValue={(item) => item}
                                        value={directQuote.quote.postcode}
                                        inputProps={{ placeholder: 'Enter postcode' }}
                                        onSelect={(value) => dispatch(updateAttribute('postcode', value))}
                                        onChange={(event) => dispatch(updateAttribute('postcode', event.target.value))}
                                        renderItem={(item) =>
                                            <div className='autocomplete-list-item'>
                                                {item}
                                            </div>
                                        }
                                    />
                                </div>
                            </>
                        ) : (
                            <span className='details'>{directQuote.quote.postcode}</span>
                        )}
                    </div>

                    {!editPostcode && <div className='form-edit' onClick={handleEditPostcode}>Edit</div>}
                </div>

                <div className='form-group'>
                    <label htmlFor='addressType'>Type of address</label>
                    <input type='radio' id='home' name='addressType' required value='Home' checked={directQuote.quote.addressType === 'Home'} onChange={(event) => dispatch(updateAttribute('addressType', event.target.value))}/>
                    <label htmlFor='home' className={`radio ${(directQuote.quote.addressType === 'Home') ? '-selected' : ''}`}>Home</label>

                    <input type='radio' id='friendhome' name='addressType' required value='Friend Home' checked={directQuote.quote.addressType === 'Friend Home'} onChange={(event) => dispatch(updateAttribute('addressType', event.target.value))}/>
                    <label htmlFor='friendhome' className={`radio ${(directQuote.quote.addressType === 'Friend Home') ? '-selected' : ''}`}>Friend’s/Relative’s home</label>

                    <input type='radio' id='rented' name='addressType' required value='Rented Venue' checked={directQuote.quote.addressType === 'Rented Venue'} onChange={(event) => dispatch(updateAttribute('addressType', event.target.value))}/>
                    <label htmlFor='rented' className={`radio ${(directQuote.quote.addressType === 'Rented Venue') ? '-selected' : ''}`}>Rented venue</label>
                </div>

                {directQuote.quote.postcode != '' && (!isPostcodeValid || isInternational) &&
                    <InfoBlock 
                        icon={'yh-new-icon-Alert---Circle'}
                        description={`If you have an international query and you need a chef flying from the UK, please email info@yhangry.com`}
                    />
                }
            </>
        );
    };

    return (
        <CoreStep
            title={title}
            isValid={isValid}
            stepName={stepName}
            renderStep={renderStep}
            isLastStep={isLastStep}
            progressBlock={progressBlock}
            progressTitle={progressTitle}
            stepsToSkipNext={stepsToSkipNext}
            stepsToSkipPrev={stepsToSkipPrev}
        />
    );
}
