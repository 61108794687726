import React from 'react';
import RequestWizardBody from './RequestWizardBody';
import RequestWizardFooter from '../Partials/RequestWizardFooter';
import RequestWizardLoading from '../States/RequestWizardLoading';

export default function RequestWizardEmbed() {
    if (is_node) {
        return (
            <div className="request-wizard__step flex-col my-5">
                <RequestWizardLoading />
            </div>
        );
    }

    return (
        <div className="request-wizard">
            <div className="request-wizard__embedded">
                <RequestWizardBody />
                <RequestWizardFooter />
            </div>
        </div>
    );
}
