import React from 'react';
import Debugger from '../../../../utils/Debugger';

class ErrorWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    setWithExpiry(key, value, ttl) {
        const item = {
            value: value,
            expiry: new Date().getTime() + ttl,
        };
        localStorage.setItem(key, JSON.stringify(item));
    }

    getWithExpiry(key) {
        const itemString = window.localStorage.getItem(key);
        if (!itemString) return null;

        const item = JSON.parse(itemString);
        const isExpired = new Date().getTime() > item.expiry;

        if (isExpired) {
            localStorage.removeItem(key);
            return null;
        }

        return item.value;
    }

    componentDidCatch(error, errorInfo) {
        // Log the error
        let message = this.props.message ?? 'Chef onboarding issue';
        Debugger.paymentError(message + ' => ' + error.message);

        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        const dynamicModule = /Failed to fetch dynamically/;

        if (
            error?.message &&
            (chunkFailedMessage.test(error.message) ||
                dynamicModule.test(error.message))
        ) {
            if (!this.getWithExpiry('yh_chunk_failed')) {
                this.setWithExpiry('yh_chunk_failed', 'true', 10000);
                window.location.reload();
            }
        }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            if (this.props.view) return this.props.view;

            return (
                <div className="row">
                    <div className="col-12 col-lg-10 offset-lg-1 align-items-center">
                        <h1 className="fw-light font-lower">
                            Something went wrong!
                        </h1>
                        <p>
                            Please refresh the page and try again, if this
                            problem persists, please get in touch with us
                        </p>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorWrapper;
