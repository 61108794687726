import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import useTracking from '@/hooks/wizard/useTracking';
import useWizard from '@/hooks/wizard/useWizard';
import ReduxContainer from '@/ReduxContainer';
import ErrorWrapper from '@/views/ChefsDashboard/Components/Utils/ErrorWrapper';
import RequestWizardModal from './Containers/RequestWizardModal';
import RequestWizardEmbed from './Wrappers/RequestWizardEmbed';
import { toast } from '@/utils/Toast';

const RequestWizard = ({
    id = 'bespokeQuoteModal',
    autoShow = false,
    embed = false,
}) => {
    const tracking = useTracking(); // Register tracking events
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const Wizard = useWizard();

    // Initialize the wizard
    if (!is_node) {
        Wizard.init({
            modalId: id,
            embed,
            autoShow,
            isMobile,
        });
    }

    return embed ? <RequestWizardEmbed /> : <RequestWizardModal />;
};

const ErrorBlock = () => {
    const [toastShown, setToastShown] = useState(false);

    useEffect(() => {
        if (toastShown) return;
        setToastShown(true);

        toast({
            title: 'Unexpected error',
            text: 'Please contact support for assistance.',
            type: 'error',
        });
    }, [toastShown]);

    return <></>;
};

export default function RequestWizardContainer(props) {
    return (
        <ErrorWrapper
            view={<ErrorBlock />}
            message="Request Wizard Wrapper Error">
            <ReduxContainer>
                <RequestWizard {...props} />
            </ReduxContainer>
        </ErrorWrapper>
    );
}
