import React from 'react';

export default function Logo(props) {
    return (
        <svg {...props} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.73 40.49">
            <defs>
                <style>{`.cls-1{fill:#1d1d55;}`}</style>
            </defs>
            <path className="cls-1" d="M130.16,42.11a8.08,8.08,0,0,0-5-1.69,9.59,9.59,0,0,0,0,19.18,8.08,8.08,0,0,0,5-1.69v2.72a4.82,4.82,0,0,1-9.27,2.12l-4,2.32a9.58,9.58,0,0,0,8.33,4.79,9.31,9.31,0,0,0,9.63-9.23V41.17h-4.72Zm-4.91,13.41c-2.71,0-4.91-2.48-4.91-5.51s2.2-5.5,4.91-5.5,4.91,2.48,4.91,5.5S128,55.52,125.25,55.52Z" transform="translate(-29.48 -29.38)"/>
            <path className="cls-1" d="M143.66,42.07v-.9h-4.72V58.85h4.72V48.28a4,4,0,0,1,4.13-3.89,11.73,11.73,0,0,1,2.47.39v-4.2a15.47,15.47,0,0,0-2.32-.2A5.42,5.42,0,0,0,143.66,42.07Z" transform="translate(-29.48 -29.38)"/>
            <path className="cls-1" d="M42.72,60.63a4.82,4.82,0,0,1-9.27,2.12l-4,2.32a9.56,9.56,0,0,0,8.33,4.79,9.31,9.31,0,0,0,9.63-9.23h0V41.15H42.72V51.69a3.85,3.85,0,0,1-3.93,3.69,3.28,3.28,0,0,1-3.54-3.26v-11H30.54V52.32a7.51,7.51,0,0,0,7.74,7.31,6,6,0,0,0,4.44-1.69v2.69Z" transform="translate(-29.48 -29.38)"/>
            <path className="cls-1" d="M60.66,40.38a6,6,0,0,0-4.44,1.69V29.38H51.5V58.85h4.72V48.32a3.85,3.85,0,0,1,3.93-3.69,3.28,3.28,0,0,1,3.54,3.26v11H68.4V47.69A7.51,7.51,0,0,0,60.66,40.38Z" transform="translate(-29.48 -29.38)"/>
            <path className="cls-1" d="M86.49,42.11a8.12,8.12,0,0,0-5-1.69,9.59,9.59,0,0,0,0,19.18,8.12,8.12,0,0,0,5-1.69v1H91.2V41.17H86.49ZM81.57,55.52c-2.71,0-4.91-2.48-4.91-5.51s2.2-5.5,4.91-5.5S86.49,47,86.49,50,84.28,55.52,81.57,55.52Z" transform="translate(-29.48 -29.38)"/>
            <path className="cls-1" d="M104.35,40.38a6,6,0,0,0-4.44,1.69v-.9H95.2V58.86h4.71V48.32a3.85,3.85,0,0,1,3.93-3.69,3.28,3.28,0,0,1,3.54,3.26v11h4.72V47.7C112.1,43.25,108.24,40.38,104.35,40.38Z" transform="translate(-29.48 -29.38)"/>
            <path className="cls-1" d="M166.49,60.63a4.82,4.82,0,0,1-9.27,2.13l-4,2.31a9.57,9.57,0,0,0,8.33,4.8,9.32,9.32,0,0,0,9.63-9.24h0V41.16h-4.72V51.7a3.85,3.85,0,0,1-3.93,3.69A3.28,3.28,0,0,1,159,52.13v-11h-4.72V52.32a7.5,7.5,0,0,0,7.74,7.31,6,6,0,0,0,4.44-1.69v2.69Z" transform="translate(-29.48 -29.38)"/>
        </svg>
    );
}
