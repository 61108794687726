import React from 'react';

export default function AlertBlock({ icon, title, description, className = '', cta = null, titleClass = '' }) {
    return (
        <>
            {cta ? (
                <div className={`customer-checkout__warning-block d-flex ${className}`} style={{ flexDirection: 'column' }}>
                    <div className="d-flex w-100 -content">
                        <div className='icon'>
                            <i className={icon}></i>
                        </div>
        
                        <div className='copy' style={{ flex: 1 }}>
                            <span className={`title ${titleClass}`}>{title}</span>
                            <span className='description d-block' dangerouslySetInnerHTML={{ __html: description }}></span>
                        </div>
        
                        {cta &&
                            <button className='desktop-cta btn btn-dark d-none d-md-block' onClick={cta.onClick}>
                                {cta.text}
                            </button>
                        }
                    </div>
        
                    {cta &&
                        <button className='mobile-cta btn btn-dark w-100 mt-4 d-block d-md-none' onClick={cta.onClick} style={{ width: '100%' }}>
                            {cta.text}
                        </button>
                    }
                </div>
            ) : (
                <div className={`direct-quote__alert-block ${className}`}>
                    <div className='icon'>
                        <i className={icon}></i>
                    </div>

                    <div className='copy'>
                        <span className='title'>{title}</span>
                        <span className='description' dangerouslySetInnerHTML={{ __html: description }}></span>
                    </div>
                </div>
            )}
        </>
    );
}