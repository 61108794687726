import React from 'react';

import CoreStep from './CoreStep';
import { useDispatch, useSelector } from 'react-redux';
import { checkIsSelected } from '../../../utils/helpers';
import { updateAttribute } from '../../../actions/DirectQuoteActions';

export default function Dietary({progressBlock, title, progressTitle, stepsToSkipNext = false, stepsToSkipPrev = false, isLastStep = false, stepName = null}) {
    const dispatch = useDispatch();
    const directQuote = useSelector((state) => state.directQuote);

    const updateDietary = (dietary) => {
        dispatch(updateAttribute('allergies', dietary, true));
    }

    const isValid = () => {
        if (!directQuote.quote.allergies || directQuote.quote.allergies.length == 0) {
            return false;
        } else {
            return true;
        }
    }
    
    const renderStep = () => {
        return (
            <>
                <span className='addtional-content'>If you or your guest have particular needs, let us know. You can always update this later.</span>
        
                <div className='listing'>
                    {directQuote.options.dietaries.map((attribute, idx) => (
                         <label className={`listing-item btn d-flex ${(checkIsSelected(attribute, directQuote.quote.allergies)) ? '-checked' : ''}`} key={idx}>
                            <input
                                type='checkbox'
                                name='allergies'
                                value={attribute}
                                onChange={() => updateDietary(attribute)}
                                checked={checkIsSelected(attribute, directQuote.quote.allergies)}
                            />
                                {attribute}
                                <i className={checkIsSelected(attribute, directQuote.quote.allergies) ? 'yh-new-icon-Minus' : 'yh-new-icon-Plus'}></i>
                        </label>
                    ))}
                </div>

                <div className='form-group'>
                    <label htmlFor='allergies_extra'>Tell us more (optional)</label>
                    <textarea 
                        maxLength='1000'
                        id='allergies_extra' 
                        name='allergies_extra'
                        className='form-control' 
                        placeholder='Enter a description...' 
                        value={directQuote.quote.allergiesExtra}
                        onChange={(event) => dispatch(updateAttribute('allergiesExtra', event.target.value))}>
                    </textarea>
                    <span className='counter d-block'>{directQuote.quote.allergiesExtra.length}/1000</span>
                </div>
            </>
        );
    };

    return <CoreStep 
        title={title}
        isValid={isValid}
        stepName={stepName}
        renderStep={renderStep} 
        isLastStep={isLastStep}
        progressBlock={progressBlock}
        progressTitle={progressTitle}
        stepsToSkipNext={stepsToSkipNext}
        stepsToSkipPrev={stepsToSkipPrev}
    />;
}
