import React from 'react'
import { X } from '@phosphor-icons/react';
import { ToastContainer } from 'react-toastify';

export default function ToastsContainer() {

    const CloseButton = ({ closeToast }) => (
        <button type="button" className="Toastify__toast-close btn align-self-start" onClick={closeToast}>
            <X size={20} weight="regular" />
        </button>
    );

    return (
        <ToastContainer
            toastClassName="toast sho d-flex ----toast-class"
            bodyClassName="toast-body d-flex ----body-class"
            closeButton={CloseButton}
        />
    );
}
