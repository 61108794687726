import React from 'react';

export default function ErrorBlock({ icon, title, description }) {
    return (
        <>
            <div className='direct-quote__error-block'>
                <div className='icon'>
                    <i className={icon}></i>
                </div>

                <div className='copy'>
                    <span className='title'>{title}</span>
                    <span className='description' dangerouslySetInnerHTML={{__html: description}}></span>
                </div>
            </div>
        </>
    );
}