import React, { useEffect, useState } from 'react';

import Menu from './Steps/Menu';
import When from './Steps/When';
import Cookies from 'js-cookie';
import Budget from './Steps/Budget';
import Guests from './Steps/Guests';
import Dietary from './Steps/Dietary';
import Basket from './Partials/Basket';
import Location from './Steps/Location';
import Segment from '../../utils/Segment';
import { usePage } from '@inertiajs/react';
import MessageChef from './Steps/MessageChef';
import CardDetails from './Steps/CardDetails';
import ExistingQuote from './Steps/ExistingQuote';
import Authentication from './Steps/Authentication';
import { fetchUser } from '../../actions/UserActions';
import { isLoggedIn } from '../../actions/UserActions';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBasket } from '../../actions/BasketActions';
import {
    updateAttribute,
    fetchUnavailableDays,
    resetDirectQuote,
    fetchExistingQuotes,
    toStep,
} from '../../actions/DirectQuoteActions';
import Vibe from './Steps/Vibe';
import ChefPreferences from './Steps/ChefPreferences';
import Equipment from './Steps/Equipment';

const QuoteSteps = ({ directQuote }) => {
    let isLastStepVal = false;

    if (isLoggedIn() || directQuote.isLoggedIn) {
        isLastStepVal = true;
    }

    if (
        directQuote?.quote?.source == 'chef-menu' ||
        directQuote?.quote?.source == 'sample-menu' ||
        directQuote?.quote?.source == 'chef-profile' ||
        directQuote?.quote?.source == 'customer-dashboard'
    ) {
        switch (directQuote.step) {
            case 0:
                return (
                    <ExistingQuote
                        progressBlock={0}
                        stepName={'existing-quote'}
                    />
                );
            case 1:
                return (
                    <Location
                        progressBlock={1}
                        stepName={'location'}
                        title={`Where is your event?`}
                        progressTitle={`Event details`}
                    />
                );
            case 2:
                return (
                    <When
                        stepName={'when'}
                        progressBlock={1}
                        title={`When is your event?`}
                        progressTitle={`Event details`}
                    />
                );
            case 3:
                return (
                    <Guests
                        progressBlock={1}
                        stepName={'guests'}
                        title={`For how many guests?`}
                        progressTitle={`Event details`}
                    />
                );
            case 4:
                return (
                    <Dietary
                        progressBlock={2}
                        progressTitle={`Perferences`}
                        title={`Dietaries & allergies`}
                        stepName={'dietaries-allergies'}
                    />
                );
            // case 5:
            //     return (
            //         <Vibe
            //             progressBlock={3}
            //             stepName={'vibe'}
            //             title={`What does a 5-star experience look like?`}
            //             progressTitle={`Vibe`}
            //         />
            //     );
            // case 6:
            //     return (
            //         <ChefPreferences
            //             progressBlock={3}
            //             stepName={'chef-preferences'}
            //             title={`What does a 5-star experience look like?`}
            //             progressTitle={`Vibe`}
            //         />
            //     );

            // case 7:
            //     return (
            //         <Equipment
            //             progressBlock={3}
            //             stepName={'equipment'}
            //             title={`What does a 5-star experience look like?`}
            //             progressTitle={`Vibe`}
            //         />
            //     );

            case 5:
                return (
                    <Budget
                        progressBlock={4}
                        stepName={'budget'}
                        progressTitle={`Budget`}
                        title={`Let's confirm your budget`}
                    />
                );
            case 6:
                return (
                    <Menu
                        progressBlock={4}
                        stepName={'message'}
                        progressTitle={`Message`}
                        stepsToSkipNext={isLastStepVal ? 2 : 1}
                        title={
                            directQuote.quote.source == 'chef-menu' ||
                            directQuote.quote.source == 'sample-menu'
                                ? 'Tell the chef how you would like to customise the menu'
                                : 'Tell your chef what sort of menu you would like to create'
                        }
                    />
                );

            case 7:
                return (
                    <Authentication
                        progressBlock={4}
                        stepName={'login-signup'}
                        progressTitle={`Login/Signup`}
                        title={`Log in or sign up to continue`}
                    />
                );
            case 8:
                return (
                    <CardDetails
                        isLastStep={true}
                        progressBlock={4}
                        stepName={'payment'}
                        progressTitle={`Optional secure chef`}
                        stepsToSkipPrev={isLastStepVal ? 2 : 1}
                        title={`Add payment details to secure this chef`}
                    />
                );
            case 99:
                return (
                    <MessageChef
                        isLastStep={true}
                        progressBlock={99}
                        stepsToSkipPrev={99}
                        stepName={'direct-message'}
                    />
                );
        }
    }
};

const BasketMemoized = ({ directQuote, setMenu, chef, direct }) => {
    return (
        <Basket
            directQuote={directQuote}
            setMenu={setMenu}
            chef={chef}
            direct={direct}
        />
    );
};

const DirectQuoteModal = React.memo(({ chefProfile }) => {
    const dispatch = useDispatch();
    const [startQuote, setStartQuote] = useState(true);
    const setMenu = useSelector((state) => state.setMenus);
    const userData = useSelector((state) => state.userData);
    const basketData = useSelector((state) => state.basketData);
    const directQuote = useSelector((state) => state.directQuote);

    useEffect(() => {
        if (startQuote && directQuote.step == 1) {
            setStartQuote(false);

            Segment.track('directquote.started', {
                source: directQuote.quote.source,
            });
        }
    }, [directQuote.step]);

    useEffect(() => {
        const session = sessionStorage.getItem('yh-existing-direct-quote');

        if (session && isLoggedIn()) {
            const existingQuote = JSON.parse(session);

            if (!userData.hasLoaded) {
                dispatch(fetchUser());
                dispatch(fetchBasket());
                dispatch(resetDirectQuote());
                dispatch(fetchExistingQuotes());

                dispatch(updateAttribute('kids', existingQuote.kids));
                dispatch(updateAttribute('chefId', existingQuote.chefId));
                dispatch(updateAttribute('guests', existingQuote.guests));
                dispatch(updateAttribute('source', existingQuote.source));
                dispatch(updateAttribute('budget', existingQuote.budget));
                dispatch(updateAttribute('mealDate', existingQuote.mealDate));
                dispatch(updateAttribute('postcode', existingQuote.postcode));
                dispatch(updateAttribute('postcode', existingQuote.postcode));
                dispatch(updateAttribute('budgetPp', existingQuote.budgetPp));
                dispatch(updateAttribute('allergies', existingQuote.allergies));
                dispatch(updateAttribute('setMenuId', existingQuote.setMenuId));
                dispatch(
                    updateAttribute('addressType', existingQuote.addressType)
                );
                dispatch(
                    updateAttribute('selectedCard', existingQuote.selectedCard)
                );
                dispatch(
                    updateAttribute(
                        'allergiesExtra',
                        existingQuote.allergiesExtra
                    )
                );
                dispatch(
                    updateAttribute(
                        'selectedDishes',
                        existingQuote.selectedDishes
                    )
                );
                dispatch(
                    updateAttribute(
                        'foodPreferences',
                        existingQuote.foodPreferences
                    )
                );
                dispatch(toStep(8));

                $('#direct_quote_modal').modal('toggle');

                sessionStorage.removeItem('yh-existing-direct-quote');
                sessionStorage.removeItem('redirectTo');
                Cookies.remove('yh_redirect_to');
            }
            return;
        }
    }, []);

    useEffect(() => {
        $('#direct_quote_modal').on('show.bs.modal', function (e) {
            const button = $(e.relatedTarget); // Button that triggered the modal
            if (!button) return;

            dispatch(resetDirectQuote());
            dispatch(fetchExistingQuotes());
            dispatch(updateAttribute('kids', button.attr('data-kids')));
            dispatch(updateAttribute('guests', button.attr('data-guests')));
            dispatch(updateAttribute('source', button.attr('data-source')));
            dispatch(fetchBasket());
            dispatch(fetchUser());

            const setMenuId = button.attr('data-menu-id');
            if (setMenuId) {
                dispatch(updateAttribute('setMenuId', parseInt(setMenuId)));
            }

            const chefId = button.attr('data-chef-id');
            if (chefId) {
                dispatch(fetchUnavailableDays(chefId));
                dispatch(updateAttribute('chefId', parseInt(chefId)));
            }

            const source = button.attr('data-source');
            const totalPrice = button.attr('data-total-price');
            if (totalPrice) {
                if (source === 'chef-menu') {
                    dispatch(updateAttribute('budget', parseFloat(totalPrice)));
                    dispatch(
                        updateAttribute(
                            'budgetPp',
                            parseFloat(totalPrice) /
                                parseInt(button.attr('data-guests'))
                        )
                    );
                }
            }

            const postcode = button.attr('data-postcode');

            if (postcode) {
                dispatch(updateAttribute('postcode', postcode));
            }

            const mealDate = button.attr('data-meal-date');
            if (mealDate && mealDate != 'false') {
                const formattedMealDate = new Date(mealDate)
                    .toISOString()
                    .split('T')[0];
                dispatch(updateAttribute('mealDate', formattedMealDate));
            } else {
                const date = new Date();
                date.setDate(date.getDate() + 1); // Add 1 day to the current date
                const formattedMealDate = date.toISOString().split('T')[0];
                dispatch(updateAttribute('mealDate', formattedMealDate));
            }

            const closeModal = button.attr('data-close-modal');
            if (closeModal) {
                dispatch(fetchBasket());
                dispatch(updateAttribute('budget', 0));
                dispatch(updateAttribute('budgetPp', 0));

                const selectedDishes = JSON.parse(
                    button.attr('data-selected-dishes')
                );
                if (selectedDishes) {
                    dispatch(updateAttribute('selectedDishes', selectedDishes));
                }
                $(closeModal).modal('hide');
                setTimeout(() => {
                    $('body').addClass('modal-open');
                }, 800);
            }

            $('#modal_request-menu').modal('hide');
        });
    }, []);

    return (
        <div className="modal-dialog modal-dialog-fixed-height" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <span className="modal-title" id="ModalTitle">
                        {directQuote.source == 'chef-menu' ||
                        directQuote.source == 'sample-menu'
                            ? 'Personalise menu'
                            : 'Create your own menu'}
                    </span>
                    <button
                        type="button"
                        className="modal-close-button m-0"
                        data-dismiss="modal"
                        aria-label="Close">
                        <i className="yh-new-icon-Cross"></i>
                    </button>
                </div>

                {directQuote?.quote?.source &&
                    (basketData.base_setmenu_details ||
                        Object.keys(chefProfile)?.length > 0 ||
                        basketData.base_setmenu_details?.chef) == true && (
                        <div className="direct-quote__basket">
                            <BasketMemoized
                                directQuote={directQuote}
                                setMenu={basketData.base_setmenu_details}
                                chef={
                                    chefProfile
                                        ? chefProfile
                                        : basketData.base_setmenu_details.chef
                                }
                                direct={chefProfile ? true : false}
                            />
                        </div>
                    )}

                <QuoteSteps
                    setMenu={setMenu}
                    userData={userData}
                    basketData={basketData}
                    directQuote={directQuote}
                />
            </div>
        </div>
    );
});

const DirectQuote = ({ disableInertia }) => {
    let chefProfile = false;
    if (!disableInertia) {
        const { chef, chef_profile } = usePage().props;

        chefProfile = { ...chef_profile };
    }

    return (
        <div className="direct-quote">
            <div
                className="modal fade direct-quote__modal"
                data-backdrop="static"
                id="direct_quote_modal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="ModalTitle"
                aria-hidden="true">
                <DirectQuoteModal chefProfile={chefProfile} />
            </div>
        </div>
    );
};

export default DirectQuote;
