import React from 'react';
import { usePage } from '@inertiajs/react';
import NavLink from '@/elements/NavLink';
import OffCanvasNav from './OffCanvasNav';
import { FilePlus, Hamburger, ForkKnife, Coins } from '@phosphor-icons/react';
// import { CookingPot, UserFocus } from '@phosphor-icons/react';

export default function MobileNav() {
    const { auth, hideStickyNav = false } = usePage().props;

    if (auth.type == 'chef') return;

    const renderGuestNav = () => (
        <>
            <li>
                <NavLink
                    routeName="quote.landing"
                    is_static={true}
                    className="dropdown-item"
                    data-toggle="modal"
                    data-target="#bespokeQuoteModal"
                    data-validate="true"
                    data-source="navbar"
                    data-text="Find a chef"
                    // accessibility
                    aria-label="New quote"
                    title="New quote">
                    {/* <i className="yh-new-icon-File---Plus fs-20" /> */}
                    {/* <div className="btn btn-primary btn-icon btn-xxl btn-highlight">
                    </div> */}
                    <ForkKnife size={25} />
                    <span>Find a chef</span>
                </NavLink>
            </li>
            {/* <li>
                <NavLink
                    routeName="pricing-calculator"
                    is_static={true}
                    className="dropdown-item">
                    <Coins size={25} />
                    Pricing
                </NavLink>
            </li> */}
            <li>
                <NavLink
                    routeName="customer-login"
                    is_static={true}
                    className="dropdown-item"
                    data-toggle="modal"
                    data-target="#customerLoginModal">
                    <i className="yh-new-icon-User fs-20" />
                    <span>Log in</span>
                </NavLink>
            </li>
        </>
    );

    const renderCustomerNav = () => (
        <>
            <li>
                <NavLink routeName="favourites" params={{ type: 'chefs' }}>
                    <i className="yh-new-icon-Heart fs-20" />
                    <span>Favourites</span>
                </NavLink>
            </li>

            <li>
                <NavLink routeName="customers.dashboard" is_static={true}>
                    <i className="yh-new-icon-Home fs-20" />
                    <span>My events</span>
                </NavLink>
            </li>
            <li>
                <NavLink
                    routeName="my-messages"
                    is_static={true}
                    // className={unseenMessage ? '-unseen' : false}
                >
                    {auth.unseenMessages > 0 && (
                        <span className="badge badge-primary badge-circle badge-xs absolute top-1 right-3">
                            {auth.unseenMessages}
                        </span>
                    )}
                    <i className="yh-new-icon-Comment---Text fs-20" />
                    <span>Inbox</span>
                </NavLink>
            </li>
            <li>
                <NavLink
                    routeName="customers.dashboard"
                    is_static={true}
                    onClick={(ev) => {
                        ev.preventDefault();
                        $('#offcanvas-navigation').modal('show');
                    }}>
                    <i className="yh-new-icon-List---Unordered fs-20" />
                    <span>Menu</span>
                </NavLink>
            </li>
        </>
    );

    return (
        <>
            {!hideStickyNav && (
                <div className="sticky-mobile-nav -no-fill">
                    <div className="container">
                        <ul className="list-unstyled">
                            <li>
                                <NavLink routeName="chefs" is_static={true}>
                                    <i className="yh-new-icon-Search fs-20" />
                                    <span>Chefs</span>
                                </NavLink>
                            </li>
                            {/* <li>
                            <NavLink routeName="food-explorer" is_static={true}>
                                <CookingPot size={20} />
                                <span>Menus</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink routeName="chefs" is_static={true}>
                                <UserFocus size={20} />
                                <span>Chefs</span>
                            </NavLink>
                        </li> */}

                            {auth.type == 'guest' && renderGuestNav()}
                            {auth.type == 'customer' && renderCustomerNav()}
                        </ul>
                    </div>
                </div>
            )}
            <OffCanvasNav />
        </>
    );
}
