import React, { useEffect } from 'react';
import { usePage } from '@inertiajs/react';
import Modal from '@/elements/Modal';
import CustomerNavLinks from './CustomerNavLinks';
import GuestNavLinks from './GuestNavLinks';
import ChefNavLinks from './ChefNavLinks';
import NavLink from '@/elements/NavLink';
import { X } from '@phosphor-icons/react';

export default function OffCanvasNav() {
    const { auth } = usePage().props;

    useEffect(() => {
        // openModal();
    }, []);

    // const openModal = () => {
    //     $('#offcanvas-navigation').modal('show');
    // };

    // const closeModal = () => {
    //     $('#offcanvas-navigation').modal('toggle');
    // };

    const renderDropDown = () => {
        if (!auth) return;

        return (
            <>
                {auth.type == 'guest' && <GuestNavLinks />}
                {auth.type == 'customer' && <CustomerNavLinks />}
                {auth.type == 'chef' && <ChefNavLinks />}
            </>
        );
    };

    return (
        <Modal
            id="offcanvas-navigation"
            isOffCanvasPanel={true}
            backText="close"
            undismissable={true}
            className={'[&_.modal-header]:hidden'}>
            <div className="offcanvas-header">
                <div>
                    <h5
                        className="offcanvas-title d-flex align-items-center mb-0"
                        id="offcanvasWithBackdropLabel">
                        <i className="yh-new-icon-User---Circle mr-3"></i>
                        <p className="m-0">
                            Hello, {auth?.user?.first_name}
                            <br />
                            <NavLink routeName="customers.dashboard">
                                View account
                            </NavLink>
                        </p>
                    </h5>
                </div>
                <button
                    type="button"
                    className="modal-close-button close m-0"
                    data-dismiss="modal"
                    aria-label="Close">
                    <X size={20} weight="regular" />
                </button>
            </div>

            {renderDropDown()}
        </Modal>
    );
}
