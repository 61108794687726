import React from 'react';

import CoreStep from './CoreStep';
import { useDispatch, useSelector } from 'react-redux';
import { updateAttribute } from '../../../actions/DirectQuoteActions';

export default function MessageChef({progressBlock, title, progressTitle, stepsToSkipNext = false, stepsToSkipPrev = false, isLastStep = false, stepName = null}) {
    const dispatch = useDispatch();
    const directQuote = useSelector((state) => state.directQuote);

    const isValid = () => {
        if (!directQuote.quote.foodPreferences || directQuote.quote.foodPreferences.length === 0) {
            return false;
        } else {
            return true;
        }
    }
    
    const renderStep = () => {
        return (
            <>
                <div className='form-group'>
                    <label htmlFor='food_preferences'>Message chef</label>
                    <textarea 
                        rows='3'
                        minLength='0'
                        maxLength='1000'
                        id='food_preferences' 
                        name='food_preferences'
                        className='form-control' 
                        value={directQuote.quote.foodPreferences}
                        onChange={(event) => dispatch(updateAttribute('foodPreferences', event.target.value))}
                        placeholder='Eg: I want 2 more mains for the group to share so there is more variety -- plant based please. Additionally, I would like atleast two servers for the evening.'>
                    </textarea>
                    <span className='counter d-block'>{directQuote.quote.foodPreferences.length}/1000</span>
                </div>
            </>
        );
    };

    return <CoreStep 
        title={title}
        isValid={isValid}
        stepName={stepName}
        renderStep={renderStep} 
        isLastStep={isLastStep}
        progressBlock={progressBlock}
        progressTitle={progressTitle}
        stepsToSkipNext={stepsToSkipNext}
        stepsToSkipPrev={stepsToSkipPrev}
    />;
}
