import { useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateMeta } from '@/actions/wizard/RequestWizardActions';
import { useStep } from './useStep';
import useExistingRequest from './useExistingRequest';

export const usePrompt = () => {
    const dispatch = useDispatch();
    const { activeStep } = useStep();
    const { matchingRequests } = useExistingRequest();
    const { meta, options, status } = useSelector(
        (state) => state.requestWizard
    );

    // Reset active prompt on step change
    useEffect(() => {
        dispatch(updateMeta({ activePrompt: 'none' }));
    }, [meta.step]);

    // Listen for step errors and show prompt
    useEffect(() => {
        if (!activeStep.error || typeof activeStep.error !== 'string') {
            dispatch(updateMeta({ activePrompt: 'none' }));
            return;
        }

        dispatch(updateMeta({ activePrompt: 'error' }));
    }, [activeStep?.error]);

    // Listen for chefs count changes and show prompt
    useEffect(() => {
        // Allow the existing request prompt to show instead
        if (meta.stepName === 'date' && matchingRequests?.length) return;

        // Hide prompt if no chefs available
        if (options.chefsCount === 0 || status.pending) {
            dispatch(updateMeta({ activePrompt: 'none' }));
            return;
        }

        dispatch(updateMeta({ activePrompt: 'chefs-count' }));
    }, [options.chefsCount]);

    const prompts = options.prompts.map((prompt) => ({
        ...prompt,
        nodeRef: createRef(),
    }));

    const activePrompt =
        prompts.find((prompt) => prompt.name === meta?.activePrompt) ||
        prompts[0];

    return {
        prompts,
        activePrompt,
    };
};
