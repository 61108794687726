import React, { useState, useMemo } from 'react';

import CoreStep from './CoreStep';
import { useDispatch, useSelector } from 'react-redux';
import { updateAttribute } from '../../../actions/DirectQuoteActions';
import NumOfGuests from '@/views/FoodExplorer/UserOptionsContainer/NumOfGuests/NumOfGuests';

export default function Guests({progressBlock, title, progressTitle, stepsToSkipNext = false, stepsToSkipPrev = false, isLastStep = false, stepName = null}) {
    const dispatch = useDispatch();
    const directQuote = useSelector((state) => state.directQuote);

    const updateGuests = (guests) => {
        dispatch(updateAttribute('guests', guests));
    }

    const updateKids = (kids) => {
        dispatch(updateAttribute('kids', kids));
    }

    const isValid = () => {
        if (!directQuote.quote.guests || directQuote.quote.guests < 2) {
            return false;
        } else {
            return true;
        }
    }

    const renderStep = () => {
        return (
            <>
                <div className='form-group'>
                    <div className='form-item'>
                        <label htmlFor='Guests'>Guests</label>

                        <div className='guests-block'>
                            <div className='input input-block'>
                                <div>
                                    <h5 className='m-0 fw-medium'>Adults (18+)</h5>
                                </div>
                                <div className='toggle' style={{width: '120px'}}>
                                    <NumOfGuests
                                        updateGuests={updateGuests}
                                        guests={directQuote.quote.guests}
                                    />
                                </div>
                            </div>

                            <div className='input input-block'>
                                <div>
                                    <h5 className='m-0 fw-medium'>Kids (Under 18)</h5>
                                </div>
                                <div className='toggle' style={{width: '120px'}}>
                                    <NumOfGuests
                                        is_kids={true}
                                        updateGuests={updateKids}
                                        guests={directQuote.quote.kids}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return <CoreStep 
        title={title}
        isValid={isValid}
        stepName={stepName}
        renderStep={renderStep} 
        isLastStep={isLastStep}
        progressBlock={progressBlock}
        progressTitle={progressTitle}
        stepsToSkipNext={stepsToSkipNext}
        stepsToSkipPrev={stepsToSkipPrev}
    />;
}
