import React from 'react';
import { useSelector } from 'react-redux';
import RequestWizardSubmitting from '../States/RequestWizardSubmitting';
import RequestWizardStep from '../Containers/RequestWizardStep';
import RequestWizardSuccess from '../States/RequestWizardSuccess';
import RequestWizardProgress from '@/components/Wizard/RequestWizard/Partials/RequestWizardProgress';

export default function RequestWizardBody({}) {
    const { status } = useSelector((state) => state.requestWizard);

    // if (status.submitted) return <RequestWizardSuccess />;

    if (status.submitting) return <RequestWizardSubmitting />;

    return (
        <>
            <RequestWizardProgress />
            <RequestWizardStep />
        </>
    );
}
