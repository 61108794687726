import _ from 'lodash';

// import Config from 'Config';
import Cookies from 'js-cookie';

import {
    DIRECT_QUOTE_RESET,
    DIRECT_QUOTE_SUBMIT,
    DIRECT_QUOTE_TO_STEP,
    DIRECT_QUOTE_PREV_STEP,
    DIRECT_QUOTE_NEXT_STEP,
    DIRECT_QUOTE_SUBMIT_ERROR,
    DIRECT_QUOTE_SELECT_QUOTE,
    DIRECT_QUOTE_HANDLE_LOGIN,
    DIRECT_QUOTE_UPDATE_ATTRIBUTE,
    DIRECT_QUOTE_FETCH_EXISTING_QUOTES,
    DIRECT_QUOTE_UPDATE_EXISTING_QUOTE,
    DIRECT_QUOTE_FETCH_CHEF_UNAVAILABLE_DAYS,
    DIRECT_QUOTE_UPDATE_EXISTING_QUOTE_ERROR,
} from '../constants/ActionTypes';
import Segment from '../utils/Segment';

export const onLogin = () => {
    return {
        type: DIRECT_QUOTE_HANDLE_LOGIN,
    };
};

export const resetDirectQuote = () => {
    return {
        type: DIRECT_QUOTE_RESET,
    };
};

export const updateAttribute = (attribute, value, array = false) => {
    return {
        type: DIRECT_QUOTE_UPDATE_ATTRIBUTE,
        payload: {
            attribute,
            value,
            array,
        },
    };
};

export const prevStep = (stepsToSkip = false) => {
    return {
        type: DIRECT_QUOTE_PREV_STEP,
        payload: { stepsToSkip },
    };
};

export const nextStep = (stepsToSkip = false) => {
    return {
        type: DIRECT_QUOTE_NEXT_STEP,
        payload: { stepsToSkip },
    };
};

export const toStep = (step) => {
    return {
        type: DIRECT_QUOTE_TO_STEP,
        payload: { step },
    };
};

export const fetchUnavailableDays = (chefId) => (dispatch) => {
    const url = `/chef-availability/get-data/${chefId}`;

    const request = axios
        .get(url)
        .then((response) => {
            dispatch({
                type: DIRECT_QUOTE_FETCH_CHEF_UNAVAILABLE_DAYS,
                payload: response.data,
            });
        })
        .catch((error) => {
            console.log("Unable to retrieve chef's unavailable days: ", error);
        });
};

export const fetchExistingQuotes = () => (dispatch) => {
    let url = `/quote/existing-quote`;
    const data = {};

    const request = axios
        .post(url)
        .then((response) => {
            dispatch({
                type: DIRECT_QUOTE_FETCH_EXISTING_QUOTES,
                payload: response.data,
            });
        })
        .catch((error) => {
            console.log(
                'There was an error while retrieving existing quote: ' +
                    error.response
            );
        });
};

export const selectQuote = (quote) => {
    return {
        type: DIRECT_QUOTE_SELECT_QUOTE,
        payload: { quote },
    };
};

export const updateExistingQuote = (directQuote) => (dispatch) => {
    const url = `/quote/update-existing-quote`;

    let data = {
        source: directQuote.quote?.source,
        chef_id: directQuote.quote.chefId,
        message: directQuote.quote.proposal,
        quote_id: directQuote.selectedQuote,
        // setMenuId: directQuote.quote.setMenuId,
        foodPreferences: directQuote.quote.foodPreferences,
    };

    // if quote set menu ID then add it to the data object
    if (directQuote.quote.setMenuId) {
        data.setMenuId = directQuote.quote.setMenuId;
    }

    // Update reducer
    dispatch({
        type: DIRECT_QUOTE_UPDATE_EXISTING_QUOTE,
        payload: null,
    });

    setTimeout(
        function () {
            axios
                .post(url, data)
                .then((response) => {
                    Segment.track('directquote.submit', {
                        source: directQuote.quote?.source,
                        quoteId: response.data.quote,
                    });
                    Segment.track('quote.submit', {
                        source: directQuote.quote?.source,
                        quoteId: response.data.quote,
                    });

                    window.location.href = `${Config.sub_dir}/my-quotes/${response.data.quote}/invite-chefs`;
                })
                .catch((error) => {
                    console.log(
                        'There was an error while updating an existing quote: ' +
                            error
                    );

                    dispatch({
                        type: DIRECT_QUOTE_UPDATE_EXISTING_QUOTE_ERROR,
                        payload:
                            'There was an error while updating your quote, please try again later.',
                    });
                });
        }.bind(this),
        500
    );
};

export const submitQuote = (directQuote) => (dispatch) => {
    const url = `/quote/create-direct-quote`;

    // Update reducer
    dispatch({
        type: DIRECT_QUOTE_SUBMIT,
        payload: null,
    });

    setTimeout(
        function () {
            axios
                .post(url, directQuote.quote)
                .then((response) => {
                    Segment.track('directquote.submit', {
                        source: directQuote.quote?.source,
                        quoteId: response.data?.quote,
                    });
                    Segment.track('quote.submit', {
                        source: directQuote.quote?.source,
                        quoteId: response.data?.quote,
                    });

                    $('#direct_quote_modal').modal('hide');

                    window.location.href = `${Config.sub_dir}/my-quotes/${response.data.quote}/invite-chefs`;
                })
                .catch((error) => {
                    console.log(
                        'There was an error while submitting a direct quote: ' +
                            error
                    );

                    dispatch({
                        type: DIRECT_QUOTE_SUBMIT_ERROR,
                        payload:
                            'There was an error while submitting your quote, please try again later.',
                    });
                });
        }.bind(this),
        500
    );
};

export const setSelectedDishes = (directQuote) => (dispatch, getState) => {
    const { basketData } = getState();

    let preselectedMenu = [];
    let selectedDishes = basketData?.basket?.selectedDishes;

    // The code block is checking if the `selectedDishes` array exists and has a length greater than 0. If it does, group the dishes based on their `group` property.
    if (
        selectedDishes &&
        selectedDishes.length > 0 &&
        (directQuote?.quote?.source == 'chef-menu' || directQuote === false)
    ) {
        let groupedDishes = selectedDishes.reduce((grouped, dish) => {
            let key = dish.group;
            if (!grouped[key]) {
                grouped[key] = {
                    key: key,
                    label: key,
                    amount: 'Included',
                    dishes: [],
                };
            }

            grouped[key].dishes.push({
                id: dish.id,
                name: dish.name,
                description: dish.dish_description,
            });

            return grouped;
        }, {});

        preselectedMenu = Object.values(groupedDishes);

        dispatch(updateAttribute('selectedDishes', preselectedMenu));
    }
};

export const saveQuoteToSession = () => (dispatch, getState) => {
    const { directQuote } = getState();
    const { quote } = directQuote;

    sessionStorage.setItem('yh-existing-direct-quote', JSON.stringify(quote));
    sessionStorage.setItem('redirectTo', window.location.href);
    Cookies.set('yh_redirect_to', window.location.href);
};
