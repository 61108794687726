import { useToggleFavourites } from '@/hooks/customers/useToggleFavourites';

export function PostAuth() {
    if (is_node) return false;

    const toggleFav = JSON.parse(
        sessionStorage.getItem('yh-toggle-favourites')
    );
    if (toggleFav) {
        useToggleFavourites({
            id: toggleFav.id,
            type: toggleFav.type,
        });
        sessionStorage.removeItem('yh-toggle-favourites');
    }
}
