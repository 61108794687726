import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStep } from '@/hooks/wizard/useStep';
import useWizard from '@/hooks/wizard/useWizard';
import RequestWizardPrompt from '@/views/Wizards/RequestWizard/Containers/RequestWizardPrompt';
import BookingProtection from '@/components/Wizard/RequestWizard/Partials/BookingProtection';
import Step from '../Wrappers/Step';

export default function RequestWizardFooter() {
    const { meta, status } = useSelector((state) => state.requestWizard);
    const { availableSteps, activeStep, next, prev } = useStep();
    const { canContinue, canSubmit, submit } = useWizard();
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (!submitting || status.submitting) return;
        setTimeout(() => {
            setSubmitting(false);
        }, 1000);
    }, [submitting]);

    if (activeStep.hideNav || status.submitting || status.submitted)
        return null;

    const isFirst = availableSteps?.[0]?.name === meta?.stepName;

    const backBtn = () => {
        if (isFirst) return;
        return (
            <button
                className="btn btn-dark btn-outline btn-lg w-full md:w-auto"
                onClick={() => prev()}>
                Back
            </button>
        );
    };

    const nextBtn = () => {
        const canDoNext = canContinue();
        const canDoSubmit = canSubmit();

        let copy = 'Next';
        if (
            activeStep?.skippable &&
            !activeStep?.isComplete &&
            meta.stepName != 'guests'
        )
            copy = 'Skip';
        if (canDoSubmit) copy = 'Submit';

        const handleClick = () => {
            if (canDoSubmit && !submitting) {
                setSubmitting(true);
                submit();
            } else {
                next();
            }
        };

        return (
            <button
                className="btn btn-primary btn-lg ml-2 md:!ml-auto w-full md:w-auto"
                disabled={(!canDoNext && !canDoSubmit) || submitting}
                onClick={() => handleClick()}>
                {copy}
            </button>
        );
    };

    return (
        <>
            <div className="-bg-beige-lighter w-full">
                <div className="container-lg">
                    <Step>
                        <div className="row">
                            <div className="col-12">
                                <RequestWizardPrompt />
                            </div>
                        </div>
                        <BookingProtection className="request-wizard__protection d-none d-md-flex" />
                    </Step>
                </div>
            </div>
            <div className="request-wizard__footer w-full">
                <div className="container-lg">
                    <Step>
                        <div className="row">
                            <div className="col-12">
                                <div className="flex justify-between w-full">
                                    {backBtn()}
                                    {nextBtn()}
                                </div>
                            </div>
                        </div>
                    </Step>
                </div>
            </div>
        </>
    );
}
