import { toast } from '@/utils/Toast';
import AuthTracking from '@/utils/Auth/AuthTracking';
import Segment from '@/utils/Segment';
import Axios from 'axios';

export function useToggleFavourites({
    id,
    type = 'set_menu',
    callback = null,
}) {
    if (!id) return;

    let typeText = type === 'set_menu' ? 'menu' : 'chef';

    if (window.isGuest) {
        $('#customerLoginModal')
            .modal('show')
            .find('.modal-title')
            .text(`Login to save the ${typeText} to your favourites`);

        sessionStorage.setItem('redirectTo', window.location.href);
        sessionStorage.setItem(
            'yh-toggle-favourites',
            JSON.stringify({ type: type, id: id })
        );
        AuthTracking.addData({ modal: 'favourites', favouriteType: type });

        return;
    }

    Axios.get(route('favourites.toggle'), {
        params: {
            id: id,
            type: type,
        },
    }).then((response) => {
        if (response.data.success !== true) {
            toast({
                type: 'error',
                title: `Cannot add this ${typeText} to favourites`,
                text: 'Please try again later',
            });
            callback && callback(false);
            return false;
        }

        if (response.data.status) {
            toast({
                type: 'success',
                title: `${typeText} added to favourites`,
                text: 'You can view your favourites in your account',
            });
            Segment.track('favourites.add', {
                type: type,
                id: id,
            });
            callback && callback(true);
            return true;
        } else {
            toast({
                type: 'info',
                title: `${typeText} removed from favourites`,
                text: 'You can view your favourites in your account',
            });
            callback && callback(false);
            return false;
        }
    });
}
