import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { usePrompt } from '@/hooks/wizard/usePrompt';
import ErrorWrapper from '@/views/ChefsDashboard/Components/Utils/ErrorWrapper';

export default function RequestWizardPrompt() {
    const { meta } = useSelector((state) => state.requestWizard);
    const { activePrompt } = usePrompt();

    return (
        <ErrorWrapper
            view={<></>}
            message={`Request Wizard Prompt ${meta?.activePrompt} Error`}>
            <div className="relative">
                    <div
                        className={`request-wizard__prompt absolute w-full bottom-0 -vertical ${
                            meta.activePrompt != 'none' ? '-active' : ''
                        }`}>
                        <SwitchTransition>
                            <CSSTransition
                                key={activePrompt.name}
                                nodeRef={activePrompt.nodeRef}
                                timeout={1000}
                                addEndListener={(done) => {
                                    activePrompt.nodeRef.current.addEventListener(
                                        'transitionend',
                                        done,
                                        false
                                    );
                                }}
                                classNames={'transition__swipe'}
                                unmountOnExit>
                                {(state) => (
                                    <div
                                        className="request-wizard__prompt-container"
                                        ref={activePrompt.nodeRef}>
                                        <div
                                            className={`request-wizard__prompt-${meta.activePrompt} mb-3`}>
                                            <activePrompt.component />
                                        </div>
                                    </div>
                                )}
                            </CSSTransition>
                        </SwitchTransition>
                    </div>
            </div>
        </ErrorWrapper>
    );
}
