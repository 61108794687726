import React from 'react';
import NavLink from '@/elements/NavLink';
import { usePage } from '@inertiajs/react';

export default function ChefNavLinks() {
    const { auth } = usePage().props;

    return (
        <>
            <NavLink
                routeName="chefs.dashboard"
                params={{ segment: 'bookings' }}
                is_static={true}
                className="dropdown-item">
                Jobs
            </NavLink>
            {auth.isApproved && (
                <NavLink
                    routeName="chef-quotes"
                    is_static={true}
                    className="dropdown-item">
                    Bespoke requests
                </NavLink>
            )}
            <NavLink
                routeName="my-messages"
                is_static={true}
                className="dropdown-item">
                Messages
                {auth.unseenMessages > 0 && (
                    <span className="badge badge-primary badge-circle badge-sm ml-2">
                        {auth.unseenMessages}
                    </span>
                )}
            </NavLink>
            <NavLink
                routeName="chefs.dashboard"
                params={{ segment: 'menus' }}
                is_static={true}
                className="dropdown-item">
                Menus
            </NavLink>
            <NavLink
                routeName="chef-dishes"
                is_static={true}
                className="dropdown-item">
                Dishes
            </NavLink>
            <div className="dropdown-divider"></div>
            <NavLink
                routeName="chef-faqs"
                is_static={true}
                className="dropdown-item">
                FAQs
            </NavLink>
            {auth.isApproved && (
                <>
                    <NavLink
                        routeName="chef-analytics"
                        is_static={true}
                        className="dropdown-item">
                        Analytics
                    </NavLink>
                    <NavLink
                        routeName="chef-update-availability"
                        is_static={true}
                        className="dropdown-item">
                        Availability
                    </NavLink>
                </>
            )}
            <NavLink
                routeName="chefs.dashboard"
                params={{ segment: 'profile' }}
                is_static={true}
                className="dropdown-item">
                Account settings
            </NavLink>
            <div className="dropdown-divider"></div>
            <NavLink
                routeName="logout"
                method="post"
                as="button"
                className="dropdown-item">
                Log out
            </NavLink>
        </>
    );
}
