import React from 'react';
import { isLoggedIn } from '@/actions/UserActions';

export default function ProgressBar({ directQuote, progressBlock }) {
    let isLastStepVal = false;

    if (isLoggedIn() || directQuote.isLoggedIn) {
        isLastStepVal = true;
    }

    let progressBar1 = 0;
    let progressBar2 = 0;
    let progressBar3 = 0;
    let progressBar4 = 0;
    let addtionalSteps = isLastStepVal ? 4 : 5;

    switch (progressBlock) {
        case 1:
            progressBar1 = ((directQuote.step + 1) / 4) * 100;
            progressBar2 = 0;
            progressBar3 = 0;
            progressBar4 = 0;
            break;
        case 2:
            progressBar1 = 100;
            progressBar2 = 50;
            progressBar3 = 0;
            progressBar4 = 0;
            break;
        case 3:
            progressBar1 = 100;
            progressBar2 = 100;
            progressBar3 = ((directQuote.step - 4) / 3) * 100;
            progressBar4 = 0;
            break;
        case 4:
            progressBar1 = 100;
            progressBar2 = 100;
            progressBar3 = 100;
            progressBar4 = ((directQuote.step - addtionalSteps) / 4) * 100;
            break;
    }

    return (
        <div className="modal-progress">
            <div className="progress-block">
                <div className="progress">
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: progressBar1 + '%' }}
                        aria-valuenow={progressBar1}
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                </div>
                <div className="progress">
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: progressBar2 + '%' }}
                        aria-valuenow={progressBar2}
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                </div>
                <div className="progress">
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: progressBar3 + '%' }}
                        aria-valuenow={progressBar3}
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                </div>
                <div className="progress">
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: progressBar4 + '%' }}
                        aria-valuenow={progressBar4}
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                </div>
            </div>
        </div>
    );
}
