import React from 'react';

import CoreStep from './CoreStep';
import Checkout from '../Partials/Checkout';
import AlertBlock from '../Partials/AlertBlock';
import { useDispatch, useSelector } from 'react-redux';
import { updateAttribute } from '../../../actions/DirectQuoteActions';

export default function CardDetails({progressBlock, title, progressTitle, stepsToSkipNext = false, stepsToSkipPrev = false, isLastStep = false, stepName = null}) {
    const dispatch = useDispatch();
    const directQuote = useSelector((state) => state.directQuote);

    const isValid = () => {
        return true;
    }

    const renderStep = () => {
        return (
            <>
                <AlertBlock 
                    icon={'yh-new-icon-Lightning'}
                    title={`Pay after confirmation`}
                    description={`You will discuss in detail the menu and price with the chef, only then will you be charged.`}
                />

                <Checkout 
                    updateCard={(card) => dispatch(updateAttribute('selectedCard', card))}
                    selectedCard={directQuote?.quote?.selectedCard?.value}
                />

                <AlertBlock 
                    icon={'yh-new-icon-Alert---Circle'}
                    title={`You won't be charged yet!`}
                    description={`You will pay once you accept the chef's menu.`}
                />
            </>
        );
    };

    return <CoreStep 
        title={title}
        isValid={isValid}
        stepName={stepName}
        renderStep={renderStep} 
        isLastStep={isLastStep}
        progressBlock={progressBlock}
        progressTitle={progressTitle}
        stepsToSkipNext={stepsToSkipNext}
        stepsToSkipPrev={stepsToSkipPrev}
    />;
}