import React, { useState, useEffect } from 'react';

import CoreStep from './CoreStep';
import InfoBlock from '../Partials/InfoBlock';
import { useDispatch, useSelector } from 'react-redux';
import { updateAttribute } from '../../../actions/DirectQuoteActions';

export default function Menu({progressBlock, title, progressTitle, stepsToSkipNext = false, stepsToSkipPrev = false, isLastStep = false, stepName = null}) {
    const dispatch = useDispatch();
    const [outlineClass, setOutlineClass] = useState('');
    const [accordionOpen, setAccordionOpen] = useState(false);
    const directQuote = useSelector((state) => state.directQuote);

    useEffect(() => {
        if (directQuote.quote.selectedCard?.value == 'new_card') {
            dispatch(updateAttribute('selectedCard', false));
        } 
    }, []);

    const toggleAccordion = () => {
        setAccordionOpen(!accordionOpen);
    };

    const isValid = () => {
        if (!directQuote.quote.foodPreferences || directQuote.quote.foodPreferences.length == 0) {
            return false;
        } else {
            return true;
        }
    }

    const handleMessageChef = (value) => {
        if (value.length === 0) {
            setOutlineClass('-red');
        } else if (directQuote.quote.foodPreferences.length < 100) {
            setOutlineClass('-orange');
        } else {
            setOutlineClass('-green');
        }

        dispatch(updateAttribute('foodPreferences', value));
    };
        
    const SelectedDishes = () => {
        let selectedDishes = directQuote.quote.selectedDishes;
      
        if (!selectedDishes || selectedDishes.length === 0) return null;
      
        // Create an object with keys as course keys and values as the corresponding courses
        const coursesMap = selectedDishes.reduce((map, course) => {
            const courseKey = course.key;
            
            if (!map[courseKey]) {
                map[courseKey] = [];
            }
            
            map[courseKey] = map[courseKey].concat(course.dishes);
            
            return map;
        }, {});
        
        return (
            <div className='selected-menu'>
                {Object.entries(coursesMap).map(([courseLabel, course], index) => {
                    return (
                        <div key={index}>
                            <h2>{courseLabel}</h2>
                            <div className='dishes'>
                                {course.map((dish, dishIndex) => (
                                    <div className='dish-block' key={dishIndex}>
                                        <span className='d-block dish-name'>{dish.name}</span>
                                        {dish.description !== null && dish.description !== 'null' && (
                                            <span className='d-block dish-description'>{dish.description}</span>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };
      
    const renderStep = () => {
        let selectedDishes = directQuote.quote.selectedDishes;   

        return (
            <>
                {(directQuote.quote.source == 'chef-menu' || directQuote.quote.source == 'sample-menu') && selectedDishes && selectedDishes.length > 0 && (
                    <div className='menu-block'>
                        <span className='intro d-block'>You can personalise menu dishes or ask for the chef to confirm servers for the event.</span>
                        <div id='accordion'>
                            <h5 className='mb-0'>
                                <button
                                    className={`btn btn-link ${accordionOpen ? 'collapsed' : ''}`}
                                    onClick={toggleAccordion}
                                    aria-expanded={accordionOpen ? 'true' : 'false'}
                                    aria-controls='collapseOne'
                                >
                                    {(accordionOpen) ? 'Hide all dishes' : 'Show all dishes'}
                                    <div className={`chevron ${accordionOpen ? 'open' : ''}`}></div>
                                </button>
                            </h5>

                            <div
                                id='collapseOne'
                                className={`menu-body ${accordionOpen ? '' : 'collapse'}`}
                                aria-labelledby='headingOne'
                                data-parent='#accordion'
                            >
                                <SelectedDishes />
                            </div>
                        </div>
                    </div>
                )}

                <div className='form-group'>
                    <label htmlFor='food_preferences'>Message chef</label>
                    <textarea 
                        rows='3'
                        minLength='0'
                        maxLength='1000'
                        id='food_preferences' 
                        name='food_preferences'
                        value={directQuote.quote.foodPreferences}
                        className={`form-control chef-message ${outlineClass}`} 
                        onChange={(event) => handleMessageChef(event.target.value)}
                        placeholder='Eg: I want 2 more mains for the group to share so there is more variety -- plant based please. Additionally, I would like atleast two servers for the evening.'>
                    </textarea>
                    <span className={`counter d-block ${outlineClass}`}>{directQuote.quote.foodPreferences.length}/1000</span>
                </div>
            </>
        );
    };

    return <CoreStep 
        title={title}
        isValid={isValid}
        stepName={stepName}
        renderStep={renderStep} 
        isLastStep={isLastStep}
        progressBlock={progressBlock}
        progressTitle={progressTitle}
        stepsToSkipNext={stepsToSkipNext}
        stepsToSkipPrev={stepsToSkipPrev}
    />;
}
