import React from 'react';

export default function InfoBlock({ icon, description, className = ''}) {
    return (
        <>
            <div className={`direct-quote__info-block ${className}`}>
                <div className='icon'>
                    <i className={icon}></i>
                </div>

                <div className='copy'>
                    <span className='description' dangerouslySetInnerHTML={{__html: description}}></span>
                </div>
            </div>
        </>
    );
}