import React from 'react';

export default function SelectBlock({ title, quoteId, postcode, icon, selectQuote, mealDate, checked = false }) {
    // Check if icon is a string or a component
    const isIconAComponent = typeof icon === 'object' && icon.$$typeof === Symbol.for('react.element');

    return (
        <button className={`direct-quote__select-block ${(checked) ? '-selected' : ''}`} key={quoteId} onClick={() => selectQuote(quoteId)}>
            <div className='icon'>
                {isIconAComponent
                    ? icon 
                    : <i className={icon}></i>
                }
            </div>

            <div className='copy'>
                <span className='title'>{title}</span>

                <div className='further-detail'>
                    <span className='description'><i className='yh-new-icon-Calendar'></i>{mealDate}</span>
                    <span className='inline-separator'> • </span>
                    <span className='description'><i className='yh-new-icon-Pin'></i>{postcode}</span>
                </div>
            </div>
            
            <div className='radio-block'>
                <input type='radio' id='home' name='addressType' required readOnly value={quoteId} checked={checked}/>
                <label htmlFor='home' className={`radio ${(checked) ? '-selected' : ''}`}></label>
            </div>
        </button>
    );
}