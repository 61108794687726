import React, { useState, useEffect } from 'react';

import { aws } from '@/config';
import { Star, FileText } from '@phosphor-icons/react';

export default function Basket({ directQuote, setMenu, chef, direct = false }) {
    const [selectedQuoteVal, setSelectedQuoteVal] = useState(null);

    useEffect(() => {
        const matchedQuote = directQuote.existingQuotes.find(
            (q) => q.id === directQuote.selectedQuote
        );
        setSelectedQuoteVal(matchedQuote);
    }, [directQuote.selectedQuote, directQuote.existingQuotes]);

    const SelectedQuote = () => {
        const iconClass =
            directQuote?.options?.typeIconMapping[selectedQuoteVal?.type];
        const iconComponent =
            directQuote?.options?.typeIconComponentMapping[
                selectedQuoteVal?.type
            ];
        const icon = iconComponent || iconClass || 'yh-new-icon-Cutlery';

        const isIconAComponent =
            typeof icon === 'object' &&
            icon.$$typeof === Symbol.for('react.element');

        return (
            <div className="direct-quote__content-block">
                <div className="icon">
                    {isIconAComponent ? icon : <i className={icon}></i>}
                </div>

                <div className="copy">
                    <span className="title">{selectedQuoteVal.type}</span>

                    <div className="further-detail">
                        <span className="description">
                            <i className="yh-new-icon-Calendar"></i>
                            {selectedQuoteVal.meal_date_formatted}
                        </span>
                        <span className="inline-separator"> • </span>
                        <span className="description">
                            <i className="yh-new-icon-Pin"></i>
                            {selectedQuoteVal.postcode}
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="main-content">
                {(directQuote?.quote?.source == 'chef-menu' || directQuote?.quote?.source == 'sample-menu') && (
                    <>
                        <div className="img-wrapper">
                            {setMenu?.image || setMenu?.thumbnail_webp ? (
                                <img
                                    data-sizes="auto"
                                    sizes="(min-width: 100vw)"
                                    src={`${aws.url}/${
                                        setMenu?.thumbnail_webp
                                            ? setMenu?.thumbnail_webp
                                            : setMenu?.image
                                    }`}
                                    data-src={`${aws.url}/${
                                        setMenu?.image_webp
                                            ? setMenu?.image_webp
                                            : setMenu?.image
                                    }`}
                                    alt={`Chef ${chef?.name}'s ${setMenu?.name} menu image`}
                                    loading="lazy"
                                    className="lazyload blur-up img-fluid"
                                />
                                ) : (
                                    <div className='btn btn-dark mr-3 d-flex justify-center align-items-center w-[100px] h-[100px]'>
                                        <FileText size={50} />
                                    </div>
                                )
                            } 
                            
                                
                           
                        </div>

                        <div className="content-wrapper">
                            <span className="title">{setMenu?.name}</span>

                            <div className="chef-details-block">
                                <div className="left-block">
                                    <span className="chef-name d-block">
                                        by Chef {chef?.name}
                                    </span>

                                    {chef?.review > 0 && (
                                        <div className="chef-review d-block d-md-flex align-items-center">
                                            <Star
                                                size={16}
                                                color="#FF6854"
                                                weight="fill"
                                            />
                                            <span>
                                                {chef?.review} (
                                                {chef?.chefFeedbackCount}{' '}
                                                reviews)
                                            </span>
                                        </div>
                                    )}
                                </div>

                                <div className="right-block">
                                    <div className="hero-image">
                                        {setMenu ? (
                                            <img
                                                data-sizes="auto"
                                                sizes="(min-width: 100vw)"
                                                src={
                                                    chef.thumbnail_webp
                                                        ? chef.thumbnail_webp
                                                        : chef.thumbnail
                                                }
                                                data-src={
                                                    chef.thumbnail_webp
                                                        ? chef.thumbnail_webp
                                                        : chef.thumbnail
                                                }
                                                alt={`Chef ${chef?.name}'s ${setMenu?.name} menu image`}
                                                loading="lazy"
                                                className="lazyload blur-up img-fluid rounded-circle"
                                            />
                                        ) : (
                                            <img
                                                data-sizes="auto"
                                                sizes="(min-width: 100vw)"
                                                src={`${aws.url}/${
                                                    chef.thumbnail_webp
                                                        ? chef.thumbnail_webp
                                                        : chef.thumbnail
                                                }`}
                                                data-src={`${aws.url}/${
                                                    chef.thumbnail_webp
                                                        ? chef.thumbnail_webp
                                                        : chef.thumbnail
                                                }`}
                                                alt={`Chef ${chef?.name}'s ${setMenu?.name} menu image`}
                                                loading="lazy"
                                                className="lazyload blur-up img-fluid rounded-circle"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {directQuote?.quote?.source == 'chef-profile' && (
                    <div className="introduction d-flex w-100">
                        <div className="hero-image">
                            <img
                                data-sizes="auto"
                                sizes="(min-width: 100vw)"
                                src={
                                    direct
                                        ? `${chef?.thumbnail}`
                                        : `${aws.url}/${chef?.thumbnail}`
                                }
                                data-src={
                                    direct
                                        ? `${chef?.thumbnail_webp}`
                                        : `${aws.url}/${chef?.thumbnail_webp}`
                                }
                                alt={`Chef ${chef?.name}'s profile image`}
                                className="lazyload blur-up img-fluid rounded-circle"
                            />
                        </div>

                        <div className="profile-header">
                            <div className="flex-grow-1 align-items-center">
                                <span className="chef-name">
                                    Chef {chef?.name}
                                </span>

                                {chef?.chefFeedbackCount > 0 && (
                                    <div className="chef-review d-block d-md-flex align-items-center">
                                        <Star
                                            size={18}
                                            color="#FF6854"
                                            weight="fill"
                                        />
                                        <span>
                                            {chef?.review} (
                                            {chef?.chefFeedbackCount} reviews)
                                        </span>
                                    </div>
                                )}
                            </div>

                            <ul className="description_list">
                                <li className="d-flex align-items-center">
                                    <i className="yh-new-icon-Pin"></i>
                                    {chef?.chefCity}
                                </li>
                                <li className="d-flex align-items-center">
                                    <i className="yh-new-icon-Wallet"></i>
                                    {`From £${chef?.min_pp} pp/Min spend £${chef?.min_spend}`}
                                </li>

                                {chef?.chefJobsCount > 0 && (
                                    <li className="d-flex align-items-center">
                                        <i className="yh-new-icon-User---Check"></i>
                                        {`${chef?.chefJobsCount} yhangry events`}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                )}
            </div>

            {selectedQuoteVal && directQuote.step != 0 && <SelectedQuote />}
        </>
    );
}
