import React from 'react';
import { useSelector } from 'react-redux';
import { useStep } from '@/hooks/wizard/useStep';
import useListeners from '@/hooks/wizard/useListeners';
import useWizard from '@/hooks/wizard/useWizard';
import Logo from '@/elements/Logo';
import Modal from '@/elements/Modal';
import RequestWizardBody from '../Wrappers/RequestWizardBody';
import RequestWizardFooter from '../Partials/RequestWizardFooter';
import { X } from '@phosphor-icons/react';

export default function RequestWizardModal({}) {
    const listners = useListeners(); // keep
    const { meta, status } = useSelector((state) => state.requestWizard);
    const { exit } = useWizard();
    const { activeStep } = useStep();

    const renderHeaderActions = () => {
        return (
            <div className="flex">
                <button
                    className="btn btn-dark btn-outline btn-icon btn-sm d-flex items-center justify-center"
                    onClick={(e) => {
                        e.preventDefault();
                        exit('Exit button', 'X');
                    }}>
                    <X size={20} />
                </button>
            </div>
        );
    };

    return (
        <div className="request-wizard">
            <Modal
                id={meta.modalId || ''}
                className="request-wizard__modal"
                title={<Logo height={meta.isMobile ? '24px' : '40px'} />}
                headerActions={renderHeaderActions()}
                scollableBody={true}
                undismissable={true}
                stickyHeader={true}
                disableMobileSlideUp={true}
                fullscreen={true}
                footer={
                    !activeStep.hideNav &&
                    !status.submitting &&
                    !status.submitted && <RequestWizardFooter />
                }>
                <RequestWizardBody />
            </Modal>
        </div>
    );
}
