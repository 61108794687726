import React, { useEffect } from 'react';

import Segment from '../../../utils/Segment';
import ErrorBlock from '../Partials/ErrorBlock';
import { useDispatch, useSelector } from 'react-redux';
import {
    prevStep,
    nextStep,
    updateExistingQuote,
    submitQuote,
} from '../../../actions/DirectQuoteActions';
import ProgressBar from '../Partials/ProgressBar';

export default function CoreStep({
    title,
    isValid,
    renderStep,
    progressBlock,
    progressTitle,
    stepsToSkipNext = false,
    stepsToSkipPrev = false,
    isLastStep = false,
    disableNextCta = false,
    stepName = null,
}) {
    const dispatch = useDispatch();
    let ctaCopy = isLastStep ? 'Submit' : 'Next';
    const directQuote = useSelector((state) => state.directQuote);

    useEffect(() => {
        if (stepName != 'existing-quote') {
            Segment.track('directquote.step_' + stepName);
        }
    }, []);

    const handlePrevStep = () => {
        dispatch(prevStep(stepsToSkipPrev));
    };

    const handleNextStep = () => {
        if (isLastStep && !directQuote.selectedQuote) {
            dispatch(submitQuote(directQuote));
        } else if (isLastStep && directQuote.selectedQuote) {
            dispatch(updateExistingQuote(directQuote));
        } else {
            dispatch(nextStep(stepsToSkipNext));
        }
    };

    const renderFooter = React.useMemo(() => {
        if (progressBlock <= 0) return;

        if (directQuote.isSubmitting) {
            ctaCopy = 'Submiting...';
        }

        return (
            <div className="modal-footer">
                {progressBlock < 10 && (
                    <ProgressBar
                        directQuote={directQuote}
                        progressBlock={progressBlock}
                    />
                )}

                <div
                    className={`cta-block ${
                        directQuote.step == 1 &&
                        directQuote.existingQuotes.length == 0
                            ? '-start'
                            : ''
                    }`}>
                    {directQuote.step > 1 && (
                        <button
                            className="btn btn-dark btn-outline"
                            onClick={handlePrevStep}
                            disabled={directQuote.isSubmitting}>
                            Back
                        </button>
                    )}

                    {directQuote.step == 1 &&
                        directQuote.existingQuotesLoaded &&
                        directQuote.existingQuotes.length > 0 && (
                            <button
                                className="btn btn-dark btn-outline"
                                onClick={handlePrevStep}
                                disabled={directQuote.isSubmitting}>
                                Back
                            </button>
                        )}

                    {!disableNextCta && (
                        <button
                            className="btn btn-primary"
                            onClick={handleNextStep}
                            disabled={!isValid() || directQuote.isSubmitting}>
                            {ctaCopy}
                        </button>
                    )}
                </div>
            </div>
        );
    }, [directQuote.step, handlePrevStep, handleNextStep, isValid]);

    return (
        <>
            <div className="modal-body">
                <div className="direct-quote__steps">
                    <div className="main-content">
                        {progressBlock > 0 && progressBlock < 10 && (
                            <div className="progress-block">
                                <span className="progression">
                                    {progressBlock}/4
                                </span>
                                <span className="title">{progressTitle}</span>
                            </div>
                        )}
                        <span className="step-title">{title}</span>

                        {directQuote.error && (
                            <ErrorBlock
                                title="An error has occured"
                                description={directQuote.error}
                                icon="yh-new-icon-Alert---Octagon"
                            />
                        )}

                        {renderStep()}
                    </div>
                </div>
            </div>

            {renderFooter}
        </>
    );
}
