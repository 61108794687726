import React from 'react';
import Page from '@/Layouts/Page';
import SEO from '@/utils/SEO';
import ErrorMessage from '@/views/Error/ErrorMessage';

export default function Error({ status = 'unexpected' }) {
    return (
        <Page>
            <SEO />
            <ErrorMessage status={status} />
        </Page>
    );
}
