import React, { useMemo } from 'react';

import PaymentForm from './PaymentForm';
import { stripe } from '@/config';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

export default function Checkout({
    updateCard,
    selectedCard,
    label = null,
    error = false,
}) {
    const stripePromise = useMemo(() => loadStripe(`${stripe.key}`), []);

    return (
        <>
            <div className="direct-quote__checkout">
                <>
                    <Elements stripe={stripePromise} locale="en-gb">
                        <PaymentForm
                            updateCard={updateCard}
                            selectedCard={selectedCard}
                            label={label}
                            formError={error}
                        />
                    </Elements>
                </>
            </div>
        </>
    );
}
