import React, { useEffect } from 'react';

import CoreStep from './CoreStep';
import Segment from '../../../utils/Segment';
import SelectBlock from '../Partials/SelectBlock';
import { usePage } from '@inertiajs/react';
import { useDispatch, useSelector } from 'react-redux';
import {
    toStep,
    nextStep,
    selectQuote,
} from '../../../actions/DirectQuoteActions';
import ExistingQuotesList from '../Partials/ExistingQuotesList';

export default function ExistingQuote({
    progressBlock,
    title,
    progressTitle,
    stepsToSkipNext = false,
    stepsToSkipPrev = false,
    isLastStep = false,
    stepName = null,
}) {
    const dispatch = useDispatch();
    const { chef_profile } = usePage().props;
    const basketData = useSelector((state) => state.basketData);
    const directQuote = useSelector((state) => state.directQuote);

    useEffect(() => {
        if (
            directQuote.existingQuotesLoaded &&
            (!directQuote.existingQuotes ||
                directQuote.existingQuotes.length == 0)
        ) {
            dispatch(nextStep(false));
        } else if (
            directQuote.existingQuotesLoaded &&
            directQuote?.existingQuotes?.length > 0
        ) {
            Segment.track('directquote.step_' + stepName);
        }
    }, [directQuote.existingQuotes]);

    const handleSelectQuote = (quoteId) => {
        dispatch(toStep(99));
        dispatch(selectQuote(quoteId));
    };

    const handleNewQuote = () => {
        dispatch(nextStep());
        dispatch(selectQuote(false));
    };

    const isValid = () => {
        return true;
    };

    // loop through existing quotes and filter out any on the same day
    // as the basket date
    const hasExistingQuotesOnSameDay =
        directQuote?.existingQuotes?.filter((quote) => {
            return quote.meal_date == directQuote?.quote?.mealDate;
        })?.length > 0;

    const renderStep = () => {
        return (
            <>
                <div className="direct-quote__existing-quotes">
                    <span className="title d-block">
                        Select event which you want to talk about with{' '}
                        {chef_profile?.name}
                    </span>

                    <div className="quote-block">
                        {hasExistingQuotesOnSameDay ? (
                            <div className="alert alert-secondary" role="alert">
                                You already have an open quote on this date,
                                please select a different date or you can invite
                                this chef to your existing quote.
                            </div>
                        ) : (
                            <>
                                <button
                                    className="btn btn-select"
                                    onClick={() => handleNewQuote()}>
                                    Create new event
                                </button>

                                <div className="line-break">
                                    <p>or</p>
                                </div>
                            </>
                        )}

                        <ExistingQuotesList />
                    </div>
                </div>
            </>
        );
    };

    return (
        <CoreStep
            title={title}
            isValid={isValid}
            stepName={stepName}
            renderStep={renderStep}
            isLastStep={isLastStep}
            progressBlock={progressBlock}
            progressTitle={progressTitle}
            stepsToSkipNext={stepsToSkipNext}
            stepsToSkipPrev={stepsToSkipPrev}
        />
    );
}
