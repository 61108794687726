import React, { useState, useMemo } from 'react';

import CoreStep from './CoreStep';
import { useDispatch, useSelector } from 'react-redux';
import { updateAttribute } from '@/actions/DirectQuoteActions';
import Input from '@/elements/Input';
import ExistingQuotesList from '../Partials/ExistingQuotesList';

export default function When({
    progressBlock,
    title,
    progressTitle,
    stepsToSkipNext = false,
    stepsToSkipPrev = false,
    isLastStep = false,
    stepName = null,
}) {
    const dispatch = useDispatch();
    const [editMealDate, setEditMealDate] = useState(true);
    const directQuote = useSelector((state) => state.directQuote);
    // const [editMealDate, setEditMealDate] = useState(() => directQuote.quote.mealDate ? false : true);

    const formattedMealDate = useMemo(() => {
        return new Date(directQuote.quote.mealDate).toLocaleDateString(
            'en-GB',
            {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            }
        );
    }, [directQuote.quote.mealDate]);

    const handleEditMealDate = () => {
        setEditMealDate(true);
    };

    // To this:
    const updateMealDate = (mealDate) => {
        if (!mealDate) return;

        // Format the date as 'YYYY-MM-DD'
        const formattedDate = mealDate.toISOString().split('T')[0];

        dispatch(updateAttribute('mealDate', formattedDate));
    };

    const hasExistingQuotesOnSameDay =
        directQuote?.existingQuotes?.filter((quote) => {
            return quote.meal_date == directQuote?.quote?.mealDate;
        })?.length > 0;

    const isValid = () => {
        if (!directQuote.quote.mealDate || hasExistingQuotesOnSameDay) {
            return false;
        } else {
            return true;
        }
    };

    const renderStep = () => {
        return (
            <>
                <div className={`${editMealDate ? '' : 'form-group -edit'}`}>
                    <div className="form-item">
                        {editMealDate ? (
                            <Input
                                label="Event date"
                                inputType="event-date"
                                value={directQuote.quote.mealDate}
                                handleChange={updateMealDate}
                                unavailableDates={false}
                                icon={<i className="yh-new-icon-Calendar" />}
                                inputClass="-unstyled"
                            />
                        ) : (
                            <>
                                <label>Event date</label>
                                <span className="details">
                                    {formattedMealDate}
                                </span>
                            </>
                        )}
                    </div>
                    {!editMealDate && (
                        <div className="form-edit" onClick={handleEditMealDate}>
                            Edit
                        </div>
                    )}
                </div>
                {hasExistingQuotesOnSameDay && (
                    <>
                        <div className="alert alert-secondary" role="alert">
                            You already have an open quote on this date, please
                            select a different date or you can invite this chef
                            to your existing quote.
                        </div>
                        <ExistingQuotesList />
                    </>
                )}
            </>
        );
    };

    return (
        <CoreStep
            title={title}
            isValid={isValid}
            stepName={stepName}
            renderStep={renderStep}
            isLastStep={isLastStep}
            progressBlock={progressBlock}
            progressTitle={progressTitle}
            stepsToSkipNext={stepsToSkipNext}
            stepsToSkipPrev={stepsToSkipPrev}
        />
    );
}
