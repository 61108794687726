import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { usePrevious } from '@/utils/hooks';
import GoogleAnalytics from '@/utils/GoogleAnalytics';
import Segment from '@/utils/Segment';

const useTracking = () => {
    const { meta, request, status } = useSelector(
        (state) => state.requestWizard
    );
    const prevMeta = usePrevious(meta);

    // Segment tracking events names map
    const events = {
        start: 'quote.started',
        exit: 'quote.looking_for',
        submit: 'quote.submit',
        step: 'quote.step',
    };

    /**
     * MARK: Effects
     */

    // Listen for starts
    useEffect(() => {
        if (
            status.pending ||
            !meta.startedAt ||
            meta.startedAt === prevMeta?.startedAt
        )
            return;

        trackStart();
    }, [meta.startedAt]);

    // Listen for exits
    useEffect(() => {
        if (
            status.pending ||
            !meta.exitedAt ||
            meta.exitedAt === prevMeta?.exitedAt
        )
            return;

        trackExit();
    }, [meta.exitedAt]);

    // Listen for steps
    useEffect(() => {
        if (
            !meta.stepName ||
            !prevMeta?.stepName ||
            meta.stepName === prevMeta.stepName
        )
            return;

        trackStep();
    }, [meta.stepName]);

    // Listen for submits
    useEffect(() => {
        if (!meta.quoteId || meta.quoteId === prevMeta?.quoteId) return;

        trackSubmit();
    }, [meta.quoteId]);

    /**
     * MARK: Methods
     */

    const track = (event, data = {}) => {
        data = {
            revenue: String(request.budget),
            ...meta,
            ...request,
            ...data,
            wizardVersion: meta.version ?? 2,
        };

        Segment.track(event, data);
    };

    const trackStart = (data = {}) => track(events.start, data);
    const trackExit = (data = {}) => track(events.exit, data);
    const trackStep = (data = {}) => track(events.step, data);

    const trackSubmit = (data = {}) => {
        track(events.submit, data);
        GoogleAnalytics.quoteCheckout(meta.quoteId, request.budget);
    };

    return { trackStart, trackExit, trackSubmit, trackStep };
};

export default useTracking;
